import * as React from 'react'
import {
  useQuery,
  useQueryClient,
  type QueryClient,
} from '@tanstack/react-query'
import ms from 'ms'
import * as z from 'zod'
import { Referral, ReferralBuilder } from '@purposity/utils'
import * as ExpiringStorage from './expiring-storage'
import { useClerk } from '~/universal/clerk'

const STORAGE_KEY = ['purposity', 'referral'] as const
STORAGE_KEY.toString = () => STORAGE_KEY.join(':')

export function useReferral() {
  const { client } = useClerk()

  const queryClient = useQueryClient()
  const setValue = createSetValue({ queryClient })

   
  const CLIENT_ID = client?.id

  const referralQuery = useQuery({
    queryKey: [CLIENT_ID, 'referral'],
    queryFn: getValue,
    // enabled: !!CLIENT_ID,
    cacheTime: CLIENT_ID ? ms('1 day') : 30,
  })

  const referral = referralQuery.data
  React.useDebugValue(referral)

  return { referral, setReferral: setValue, query: referralQuery }
}
function createSetValue(ctx: { queryClient: QueryClient }) {
  return async (variables: Referral | null) => {
    if (typeof window !== 'undefined') {
      try {
        const value = variables
          ? await Referral.parseAsync(variables, {
              path: ['mutation'],
            })
          : null

        await ExpiringStorage.setValue(`${STORAGE_KEY}`, value)
      } catch (error) {
        if (error instanceof z.ZodError)
          console.warn('Setting referral failed', error)
        else throw error
      }
      return ctx.queryClient.invalidateQueries(STORAGE_KEY)
    }
  }
}

async function getValue() {
  if (typeof window !== 'undefined') {
    try {
      const savedValue = await ExpiringStorage.getValue(`${STORAGE_KEY}`)
      if (!savedValue) return null
      const referral = await Referral.parseAsync(savedValue)
      return ReferralBuilder(referral)
    } catch (error) {
      console.warn('An error occured while parsing the referral', error)
      return null
    }
  }
  return null
}

export const referralStorage = {
  get: getValue,
  set: createSetValue,
} as const
