import type * as z from 'zod'

export function zodInvariant<TSchema extends z.ZodTypeAny>(
  schema: TSchema,
  value: unknown
): asserts value is z.infer<TSchema> {
  const result = schema.safeParse(value, {
    errorMap: (error) => {
      return new Error(`Zod Invariant failed: ${error.message}`)
    },
  })
  if (result.success) {
    return
  }
  throw result.error
}

export type zodInvariant = typeof zodInvariant

export default zodInvariant
