import isURL from 'validator/es/lib/isURL'
import * as z from 'zod'
import type { IncomingMessage } from 'http'

/** UrlSchema
 * @description validates a string and, if possible, coerces it into a valid URL object
 * @param v href, origin, host/hostname, or pathname
 * @returns URL
 * @summary origin and href can be directly converted to a URL object
 */
export const looseUrlSchema = z
  .preprocess((v) => {
    if (v instanceof URL) return v.toString()
    return v
  }, z.string())
  .transform((v) => {
    /** returns `true` for all origins or hrefs */
    const isOriginOrHref = isURL(v, {
      require_protocol: true,
      require_host: true,
      require_tld: false,
    })
    if (isOriginOrHref) return new URL(v)

    const hostOrHostnameOptions = {
      require_protocol: false,
      require_host: true,
      require_tld: true,
    }
    const isHostOrHostname = isURL(v, hostOrHostnameOptions)
    if (isHostOrHostname) return new URL('https://' + v)

    const isLocalHostOrHostname = isURL(v, {
      ...hostOrHostnameOptions,
      require_tld: false,
    })
    if (isLocalHostOrHostname) return new URL('http://' + v)

    return new URL(v, getAbsoluteUrl().origin)
  })

export const looseHrefSchema = looseUrlSchema.transform((v) => v.href)

function getAbsoluteUrl(
  req?: IncomingMessage | Request,
  localhostAddress = 'localhost:3000'
) {
  let host, protocol

  const win = (typeof window !== 'undefined' && window) || undefined

  if (typeof req === 'undefined' || 'httpVersion' in req) {
    host =
      (req?.headers ? req.headers.host : win?.location.host) || localhostAddress

    protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'

    if (typeof req?.headers?.['x-forwarded-host'] === 'string') {
      host = req.headers['x-forwarded-host']
    }

    if (typeof req?.headers?.['x-forwarded-proto'] === 'string') {
      protocol = `${req.headers['x-forwarded-proto']}:`
    }
  } else {
    host =
      (req?.headers ? req.headers.get('host') : win?.location.host) ||
      localhostAddress

    protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'

    if (typeof req?.headers?.get('x-forwarded-host') === 'string') {
      host = req.headers.get('x-forwarded-host')!
    }

    if (typeof req?.headers?.get('x-forwarded-proto') === 'string') {
      protocol = `${req.headers.get('x-forwarded-proto')}:`
    }
  }

  return {
    /**
     * @example https
     */
    protocol,
    /**
     * @example example.com
     */
    host,
    /**
     * @example https://example.com
     */
    origin: protocol + '//' + host,
  }
}
