export class NewUserSetupError extends Error {
  constructor(
    public code:
      | keyof typeof NewUserSetupError.ErrorCode
      | (typeof NewUserSetupError.ErrorCode)[keyof typeof NewUserSetupError.ErrorCode],
    options?: ErrorOptions
  ) {
    super('ClerkNewUserError', options)
  }
}

export namespace NewUserSetupError {
  export const ErrorCode = {
    UNLOADED: 'clerk/unloaded',
    NOT_LOGGED_IN: 'clerk/not-logged-in',
    MISSING_PURPOSITY_METADATA: 'clerk/missing-purposity-metadata',
  } as const
}
