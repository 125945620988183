import * as z from 'zod'

type Literal = boolean | null | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
)
export type jsonSchema = z.infer<typeof jsonSchema>

export const jsonPipe = z.coerce.string().transform((val, ctx) => {
  try {
    const parsed = JSON.parse(val) as jsonSchema
    return z.OK<Json>(parsed)
  } catch (error) {
    ctx.addIssue({
      code: 'custom',
      fatal: true,
      message: (error as SyntaxError).message,
    })
    return z.NEVER
  }
})
