import * as z from 'zod'

export const needStateSchema = z.enum([
  'initialized',
  'unverified',
  'revisions_requested',
  'org_admin_verified',
  'published',
  'purchase_unverified',
  'purchase_verified',
  'received',
  'archived',
])
