// Fisher-Yates shuffle

import { mulberry32 } from './mulberry32'

/**
 * Fisher-Yates shuffle
 * @param {T[]} array
 * @param {number?} seed pass a value to override the randomly generated seed; if `0` is passed, the shuffle is skipped and a copy of the array is returned in it's original order
 * @returns copy of original array, shuffled (if seed is not `0`)
 */
export function shuffle<T>(array: T[], seed?: number): T[] {
  const arrayCopy = [...array]

  if (seed === 0) {
    return arrayCopy
  }

  let currentIndex, temporaryValue, randomIndex

  const SEED = seed != null ? seed : Math.floor(Math.random() * 0xffffffff)
  const rand = mulberry32(SEED)

  currentIndex = arrayCopy.length

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(rand() * currentIndex--)

    // And swap it with the current element.
    temporaryValue = arrayCopy[currentIndex]
    arrayCopy[currentIndex] = arrayCopy[randomIndex]
    arrayCopy[randomIndex] = temporaryValue
  }

  return arrayCopy
}
