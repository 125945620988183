import { initGraphQLTada } from 'gql.tada'
import type { introspection } from '../__generated/tada/graphql-env'

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    //   String: string
    //   Boolean: boolean
    //   Int: number
    //   Float: number

    //   /** YYYY-MM-DDTHH:MM:SSZ aka ISO 8601 Date and time in UTC */
    //   DateTime: string
    //   JSON: JSON
    //   bigint: number
    //   bytea: string
    //   /** YYYY-MM-DD aka ISO 8601 Date */
    //   date: string
    //   float8: number
    //   geography: GeoJSON.GeoJsonObject
    //   geometry: GeoJSON.GeometryObject
    //   json: JSON
    //   jsonb: JSON
    //   numeric: number
    //   /** number as string */
    //   seed_float: `${number}`
    //   timestamp: string
    //   timestamptz: string
    uuid: string
    push_token_type: 'expo' | 'apns' | 'fcm' | 'onesignal'
  }
}>()

export type {
  FragmentOf,
  ResultOf,
  VariablesOf,
  TadaDocumentNode,
} from 'gql.tada'

export { readFragment } from 'gql.tada'

export type { DocumentNode as DocumentNodeBase } from '@0no-co/graphql.web'
