import { Button } from '@components/Buttons/Base'
import { useReferral } from '@features/referral/useReferral'
import {
  createHideAction,
  useRoadblock,
} from '@features/roadblock/hooks/useRoadblock'
import { LOGIN_PATH, SIGNUP_PATH } from '@purposity/auth'
import * as Colors from '@themes/Colors'
import Fonts from '@themes/Fonts'
import { useRouter } from 'next/router'
import * as React from 'react'
import Modal from 'react-modal'
import { StyleSheet, Text, View } from 'react-native'

Modal.setAppElement('#__next')

export function RoadblockModal() {
  const { state, dispatch } = useRoadblock()
  const router = useRouter()
  const { setReferral } = useReferral()

  return (
    <Modal
      isOpen={state.modal}
      preventScroll
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        setReferral(null)
        dispatch(createHideAction())
      }}
      style={{
        overlay: {
          backgroundColor: Colors.darkBlue85,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

        },
        content: {
          inset: 'unset',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: '100%',
          // width: '100%',
          // height: '100%',
          // maxHeight: 500,
          border: 0,
          padding: 0,
          backgroundColor: 'unset',
          // margin: '0 auto',
          // position: 'relative',
        },
      }}
    >
      <View style={styles.container}>
        <View style={styles.emptyBoxTop}>{''}</View>

        <View style={styles.innerModal}>
          <View style={{ flex: 1, visibility: 'hidden' }}>{''}</View>

          <Button
            format="rounded"
            emphasis="tertiary"
            onClick={() => {
              setReferral(null)
              dispatch(createHideAction())
            }}
            title="×"
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              zIndex: 1, fontSize: 38
            }}
            padding={{ paddingTop: 5, paddingRight: 10, paddingBottom: 15, paddingLeft: 10 }}
          />
          <View style={authStyles.textContainer}>
            <Text style={authStyles.textHeader}>Welcome to Purposity</Text>
            <Text style={authStyles.textDescriptiopn}>
              Let’s help you get into your account.
            </Text>
          </View>
          <View style={authStyles.actionContainer}>
            <View style={{}}>
              <Button
                emphasis="secondary"
                format="rounded"
                onClick={() => {
                  router.push(LOGIN_PATH, LOGIN_PATH, { shallow: true })
                  dispatch(createHideAction())
                }}
                title="Log In"
                style={authStyles.actionButton}
              />
            </View>
            {/* <Spacer width={8} noShrink /> */}
            <View style={{}}>
              <Button
                format="rounded"
                onClick={() => {
                  router
                    .push(SIGNUP_PATH, SIGNUP_PATH, { shallow: true })
                    .then(() => dispatch(createHideAction()))
                }}
                title="Sign Up"
                style={authStyles.actionButton}
              />
            </View>
          </View>
        </View>

        <View style={styles.emptyBoxBottom}>{''}</View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '100%',

    justifyContent: 'space-around',
    alignItems: 'center',
  },
  innerModal: {
    width: '100%',
    maxWidth: 500,
    minHeight: 500,
    maxHeight: Math.pow(2, 9),
    paddingVertical: Math.pow(2, 5),
    paddingHorizontal: 48,
    backgroundColor: Colors.backgroundGray,

    borderRadius: Math.pow(2, 4),
    gap: Math.pow(2, 5),
  },
  emptyBoxTop: {
    flex: 3,
    visibility: 'hidden',
  },
  emptyBoxBottom: {
    flex: 5,
    visibility: 'hidden',
  },
})

const authStyles = StyleSheet.create({
  textContainer: {
    flex: 16,
    alignItems: 'center',
    justifyContent: 'center',
    gap: Math.pow(2, 2),
  },
  actionContainer: {
    display: 'grid',
    flexShrink: 0,

    columnGap: Math.pow(2, 3),
    rowGap: Math.pow(2, 4),

    // width: '100%',
    // gridTemplateAreas: `'item1 item2'`,
    gridTemplateColumns: 'repeat(auto-fit, minmax(198px, 1fr))',
  },

  textHeader: {
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.h4,
    color: Colors.coal,
    textAlign: 'center',
    margin: 0,
  },

  textDescriptiopn: {
    textAlign: 'center',
    color: Colors.textGray,
    fontFamily: Fonts.type.base,
    fontSize: Fonts.size.regular,
    margin: 0,

    // lineHeight: 18,
  },

  // actionButton: {
  //   minWidth: 150,
  // },
})
