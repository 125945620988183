export function formatDescription(test: string) {
  return removeDoubleSpaces(truncToSentences(removeLineBreaks(test)))

  function truncToSentences(str: string, numberOfSentences = 4) {
    let sentences = str.split(/\./g)
    if (sentences.length > numberOfSentences) {
      sentences = sentences.slice(0, numberOfSentences)
      sentences.push('...')
    }
    return sentences.join('.')
  }

  function removeLineBreaks(str: string) {
    return str.replace(/[\n\r]/g, ' ')
  }

  function removeDoubleSpaces(str: string) {
    return str.replace(/\s{2,}/g, ' ')
  }
}
