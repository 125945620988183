import * as z from 'zod'
import type { JwtPayload } from '@clerk/types'

export const ClerkDefaultSessionClaims = z.custom<JwtPayload>((value) => {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value != null &&
    'exp' in value &&
    'iat' in value &&
    'iss' in value &&
    'jti' in value &&
    'nbf' in value &&
    'sub' in value
  )
})
