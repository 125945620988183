import * as z from 'zod'
import { HasuraSessionVariables } from '../auth/hasura-sessions'

export const ActionPayloadBase = z.object({
  action: z.object({
    name: z.string(),
  }),
  input: z.any(),
  session_variables: HasuraSessionVariables,
  request_query: z.string(),
})
