import { InteractionManager } from 'react-native'
import { createRootLogger } from 'logger'

export const Log = createRootLogger(
  {},
  {
    async: true,
    asyncFunc: InteractionManager.runAfterInteractions,
  }
)
