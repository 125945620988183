import { PURPOSITY_ENV } from 'app-constants'
import { env } from 'env'
import { AppConfig } from '~/create-app-config'

export const appConfig = new AppConfig(PURPOSITY_ENV, {
  appName: 'www',
  cdnOrigin: env.NEXT_PUBLIC_CDN_ORIGIN,
  hasuraOrigin: env.NEXT_PUBLIC_API_ORIGIN,
  clerkDomain: env.NEXT_PUBLIC_CLERK_AUTH_DOMAIN,
  superOrgId: 27,
  sanity: {
    projectId: env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    dataset: env.NEXT_PUBLIC_SANITY_DATASET,
  },
  logflare: {
    apiKey: env.NEXT_PUBLIC_LOGFLARE_API_KEY,
    sourceKey: env.NEXT_PUBLIC_LOGFLARE_SOURCE_KEY,
  },
  tip: {
    percentages: [0, 0.1, 0.15, 0.2],
    defaultIndex: 2,
  },
  ga: [
    env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    env.NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE,
  ],
  fb: [env.NEXT_PUBLIC_FB_PIXEL_ID],
})
