import * as z from 'zod'
import { IdSchema, invariant } from '@purposity/utils'
import { type _CreateUniversalCheckoutMutationVariables } from '../../__generated/primary/types'
import { withBucket } from '../../lib/createBucket'
import { HeadersInit, SignalInit } from '../_common'
import type { Properties } from '../../utils'

type Params = _CreateUniversalCheckoutMutationVariables
const Params = z.object<Properties<Params>>({
  params: z.object({
    need_ids: IdSchema.array(),
    tip_percentage: z.number(),
  }),
})

export default withBucket((bucket) => {
  return z
    .function()
    .args(Params, HeadersInit, SignalInit)
    .implement(async (params, headersInit, signalInit) => {
      const result = await bucket.gql._createUniversalCheckout(
        params,
        headersInit
      )
      invariant(result.checkout, 'No checkout returned')
      return result.checkout
    })
})
