import { IdSchema } from 'utils-schemas'
import * as z from 'zod'
import {
  Referral,
  Role as LegacyRole,
  Roles as LegacyRoles,
  RolesFromRolesMask as LegacyRolesFromRolesMask,
} from '@purposity/utils'
import { ClerkId, StripeCustomerId } from './_shared'

/** @see packages/legacy-auth/src/utils/constants.ts */
const APP_VERSION = {
  APP_VERSION_WEB2: 'web2',
  /** initial version of Clerk integration */
  APP_VERSION_WEB2_CLERK: 'web2-clerk',
  /** next generation of Clerk integration: web */
  APP_VERSION_WEB3: 'clerk3-web',
  /** next generation of Clerk integration: native */
  APP_VERSION_CLERK3_NATIVE: 'clerk3-native',
  /** used during initial Clerk import */
  CLERK_IMPORT: 'clerk-import',
  /** used during 2023 Clerk migration */
  CLERK_MIGRATION: 'clerk-migration',
  /** used by `apps/legacy-api-proxy` when artificially creating a user */
  LEGACY_API_V1_CREATE: 'legacy-api/v1/create',
  /** default value instantiated by Postgres; indicates rails created the account */
  LEGACY_API: 'legacy-api',
  /** created using scripts for developer account management */
  DEV_SCRIPTS: 'dev-scripts',
  /** @deprecated do not use this value */
  OTHER: 'other',
} as const
export const APP_VERSIONS = z.nativeEnum(APP_VERSION)
export type APP_VERSIONS = z.infer<typeof APP_VERSIONS>

export const PublicMetadata = z.object({
  purposity_id: IdSchema.transform((val) => val.toString()),
  purposity_org_id: IdSchema.default(1).transform((val) => val.toString()),
  purposity_roles: LegacyRoles.or(LegacyRolesFromRolesMask).default([
    LegacyRole.enum.user_public,
  ]),
  stripe_customer_id: StripeCustomerId,
})
export type PublicMetadata = z.infer<typeof PublicMetadata>

export const PrivateMetadata = z.object({
  source: APP_VERSIONS.catch(() => APP_VERSIONS.enum.OTHER),
  dev: z
    .object({
      clerk_id: ClerkId,
      stripe_customer_id: StripeCustomerId.brand('STRIPE_TEST_ENV'),
    })
    .optional(),
})
export type PrivateMetadata = z.infer<typeof PrivateMetadata>

export const UnsafeMetadata = z.object({
  referral: Referral.optional(),
})
export type UnsafeMetadata = z.infer<typeof UnsafeMetadata>

export const PurpositySignUpUnsafeMetadata = z
  .object({
    referral: Referral,
  })
  .partial()
export type PurpositySignUpUnsafeMetadata = z.infer<
  typeof PurpositySignUpUnsafeMetadata
>

// const ClerkUser = z.custom<UserResource>((v) => {
//   // @todo: implement a more thorough check
//   return (v as UserResource)?.id?.startsWith?.('user_')
// })

// type ClerkUser = Prettify<z.infer<typeof ClerkUser>>

// const PurposityUser = ClerkUser.transform((v, ctx) => {
//   const _publicMetadata = PublicMetadata.safeParse(v.publicMetadata)
//   if (!_publicMetadata.success) {
//     _publicMetadata.error.issues.forEach((issue) => {
//       ctx.addIssue({
//         ...issue,
//         path: [...ctx.path, 'publicMetadata', ...issue.path],
//         fatal: false,
//       })
//     })
//   }

//   const _unsafeMetadata = UnsafeMetadata.safeParse(v.unsafeMetadata)
//   if (!_unsafeMetadata.success) {
//     _unsafeMetadata.error.issues.forEach((issue) => {
//       ctx.addIssue({
//         ...issue,
//         path: [...ctx.path, 'unsafeMetadata', ...issue.path],
//         fatal: false,
//       })
//     })
//   }

//   if (!_publicMetadata.success || !_unsafeMetadata.success) return z.NEVER

//   return {
//     ...v,
//     publicMetadata: _publicMetadata.data,
//     unsafeMetadata: _unsafeMetadata.data,
//   }
// })
// // type PurposityUser = z.infer<typeof PurposityUser>

// const ClerkUserServer = z.instanceof(User)

// const PurposityUserServer = ClerkUserServer.transform((v, ctx) => {
//   const _publicMetadata = PublicMetadata.safeParse(v.publicMetadata)
//   if (!_publicMetadata.success) {
//     _publicMetadata.error.issues.forEach((issue) => {
//       ctx.addIssue({
//         ...issue,
//         path: [...ctx.path, 'publicMetadata', ...issue.path],
//         fatal: false,
//       })
//     })
//   }

//   const _unsafeMetadata = UnsafeMetadata.safeParse(v.unsafeMetadata)
//   if (!_unsafeMetadata.success) {
//     _unsafeMetadata.error.issues.forEach((issue) => {
//       ctx.addIssue({
//         ...issue,
//         path: [...ctx.path, 'unsafeMetadata', ...issue.path],
//         fatal: false,
//       })
//     })
//   }

//   const _privateMetadata = PrivateMetadata.safeParse(v.privateMetadata)
//   if (!_privateMetadata.success) {
//     _privateMetadata.error.issues.forEach((issue) => {
//       ctx.addIssue({
//         ...issue,
//         path: [...ctx.path, 'privateMetadata', ...issue.path],
//         fatal: false,
//       })
//     })
//   }

//   if (
//     !_publicMetadata.success ||
//     !_unsafeMetadata.success ||
//     !_privateMetadata.success
//   )
//     return z.NEVER

//   return {
//     ...v,
//     publicMetadata: _publicMetadata.data,
//     unsafeMetadata: _unsafeMetadata.data,
//     privateMetadata: _privateMetadata.data,
//   }
// })
// type PurposityUserServer = z.infer<typeof PurposityUserServer>
