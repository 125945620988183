import { withBucket } from '../lib/createBucket'
import type { DocumentNodeBase, ResultOf, VariablesOf } from './graphql'

export function makeApiMethod<TDocument extends DocumentNodeBase>(
  document: TDocument
) {
  return withBucket(
    (bucket) =>
      (
        variables: VariablesOf<TDocument>,
        requestHeaders?: HeadersInit,
        abort?: AbortSignal
      ) => {
        return bucket.gql.__primaryClient.request<ResultOf<TDocument>>(
          document,
          // @ts-ignore
          variables,
          requestHeaders
        )
      }
  )
}
