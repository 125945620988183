import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import type { MutationContext } from '@lib/react-query'

export function useQueryWithAuth<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  queryKey,
  queryFn,
  meta,
  ...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  return useQuery({
    ...options,
    queryKey,
    queryFn,
    meta: {
      ...meta,
      headers: {},
    },
  })
}

export function useMutationWithAuth<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = MutationContext,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { mutationFn, meta, ...restOptions } = options
  return useMutation({
    mutationFn,
    ...restOptions,
    meta: {
      headers: {},
      ...meta,
    },
  })
}

export function useInfiniteQueryWithAuth<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey
  >
): UseInfiniteQueryResult<TData, TError> {
  return useInfiniteQuery({
    ...options,
    meta: {
      headers: {},
      ...options.meta,
    },
    enabled: options.enabled,
  })
}
