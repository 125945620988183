import { graphql } from '../../graphql'
import { makeApiMethod } from '../../make-api-method'

const document = graphql(`
  mutation runSignupTasks($sua_id: String!, $app_version: String!) {
    signup_tasks: run_signup_tasks(
      params: { sua_id: $sua_id, app_version: $app_version }
    ) {
      clerk_id
      stripe_customer_id
      user_id
      signup_attempt {
        abandon_at
        created_session_id
        created_user_id
        custom_action
        email_address
        external_id
        first_name
        id
        last_name
        missing_fields
        object
        optional_fields
        password_enabled
        phone_number
        required_fields
        status
        unverified_fields
        username
        web3_wallet
        verifications {
          email_address
          external_account
          phone_number
          web3_wallet
        }
      }
    }
  }
`)

export default makeApiMethod(document)
