/// <reference path="../lib.d.ts" />

import { configLoggerType, logger } from 'react-native-logs'
import { defaultConfig } from './main'
import type { Level } from './shared'

export const PACKAGE_NAME = 'logger'

export function createRootLogger(
  options?: {},
  configOverride?: configLoggerType
) {
  const derivedConfig = {
    ...defaultConfig,
    ...configOverride,
  }
  return logger.createLogger<Level>(derivedConfig)
}

export type Logger = Prettify<ReturnType<typeof createRootLogger>>
