/* eslint-disable no-process-env */
/// <reference types="node" />

import { createEnv } from '@t3-oss/env-core'
import * as z from 'zod'

export default createEnv({
  server: {
    CLERK_JWT_KEY: z.string().min(1),
    CLERK_SECRET_KEY: z
      .string()
      .min(1)
      .refine((v) => v.startsWith('sk_'), { message: 'must start with sk_' }),
  },
  runtimeEnv: {
    CLERK_JWT_KEY: process.env.CLERK_JWT_KEY,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
  },
})
