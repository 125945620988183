import { JWTVerifyGetKey, importSPKI, jwtVerify, errors, decodeJwt } from 'jose'
import { IdSchema } from 'utils-schemas'
import * as z from 'zod'
import env from '../env/server'
import { AuthError } from '~/lib/util/errors'

const LongLivedToken = z.object({
  exp: z.number().positive().int(),
  iat: z.number().positive().int(),
  iss: z
    .string()
    .url()
    .refine((v) => v.includes('clerk'), {
      message: 'issuer must be a Clerk URL',
    }),
  jti: z.string(),
  nbf: z.number().positive().int(),
  sub: z.string().startsWith('user_'),
  uid: IdSchema,
  version: z.literal(1),
})

const getClerkJwtKey: JWTVerifyGetKey = (protectedHeader, _token) => {
  const RE_SPKI_CONTENTS = /.{1,64}/g
  const spki = z
    .string()
    .regex(RE_SPKI_CONTENTS, { message: 'Invalid SPKI' })
    .transform((v) => v.match(RE_SPKI_CONTENTS) ?? [])
    .transform((v) => v.join('\n'))
    .transform(
      (v) => `-----BEGIN PUBLIC KEY-----\n${v}\n-----END PUBLIC KEY-----`
    )
    .parse(env.CLERK_JWT_KEY, {
      path: ['env', 'CLERK_JWT_KEY'],
    })

  return importSPKI(spki, protectedHeader.alg)
}

export const verifyClerkLllToken = async (token: string | unknown) => {
  if (typeof token !== 'string') throw new AuthError(AuthError.INVALID_TOKEN)
  try {
    const verified = await jwtVerify(token, getClerkJwtKey)
    switch (verified.payload.version) {
      case 1:
        return LongLivedToken.parse(verified.payload)
      default:
        throw new AuthError(AuthError.INVALID_CLAIMS, {
          cause: new Error(`Invalid version: ${verified.payload.version}`),
        })
    }
  } catch (err) {
    if (err instanceof errors.JOSEError) {
      if (err.code === errors.JWTExpired['code']) {
        // Our original JWT's had no expiration. To accomodate Clerk's `999999` second limit, we validate expired tokens if they are marked as version `1`
        const payload = decodeJwt(token)
        if (payload.version === 1) return LongLivedToken.parse(payload)
      }
      throw new AuthError(AuthError.INVALID_TOKEN, { cause: err })
    } else if (err instanceof z.ZodError) {
      throw new AuthError(AuthError.INVALID_CLAIMS, { cause: err })
    } else {
      throw err
    }
  }
}
