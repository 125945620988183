type EnvColor = {
  fillColor: string
  textColor: string
}

const _envColors = new Map<PurposityEnvironment, Partial<EnvColor>>()

_envColors.set('production', {
  fillColor: undefined,
  textColor: undefined,
})

_envColors.set('preview', {
  fillColor: '#ffdf01',
  textColor: '#000000',
})

_envColors.set('development', {
  fillColor: '#dc2727',
  textColor: '#ffffff',
})

_envColors.set('local', {
  fillColor: '#f1f3f5',
  textColor: '#5746af',
})

_envColors.set('test', {
  fillColor: '#ed5f00',
  textColor: '#000000',
})

export const envColors: ReadonlyMap<
  PurposityEnvironment,
  Partial<EnvColor>
> = _envColors
