import { graphql } from '../../graphql'
import { makeApiMethod } from '../../make-api-method'

// TODO: see if we can get bundle_id and device_type from a token
const document = graphql(`
  # Sync the device to the db; if there is a conflict, fail, and force the local device to clear its cache and check again (app restored from backup onto new device)
  # @role public
  # @when first run

  mutation initDevice(
    $bundle_id: String!
    $device_type: String!
    $local_id: uuid!
  ) {
    insert_devices(
      objects: [
        {
          device_type: $device_type
          bundle_id: $bundle_id
          local_id: $local_id
        }
      ]
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`)

export default makeApiMethod(document)
