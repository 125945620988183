 
import { SENTRY_DSN, PURPOSITY_ENV } from 'app-constants'
import * as z from 'zod'
import type { BrowserOptions, EdgeOptions, NodeOptions } from '@sentry/nextjs'
import type {
  ErrorEvent,
  TransactionEvent,
  EventProcessor,
  Options,
} from '@sentry/types'

export const isCheckly = (event: ErrorEvent | TransactionEvent) =>
  event.request?.headers?.['User-Agent']?.match?.(/checkly/i)

export const ZodProcessor: EventProcessor = function (event, hint) {
  /** Format ZodErrors for Sentry */
  if (hint.originalException instanceof z.ZodError) {
    return {
      ...event,
      extra: {
        ...event.extra,
        'validation-issues': hint.originalException.format(),
      },
    }
  }

  return event
}

export const defaultOptions:
  | Options
  | BrowserOptions
  | NodeOptions
  | EdgeOptions = {
  dsn: SENTRY_DSN,
  environment: PURPOSITY_ENV,
  enabled:
    PURPOSITY_ENV === 'production' ||
    PURPOSITY_ENV === 'local' ||
    PURPOSITY_ENV === 'preview' ||
    !!process.env.DEBUG,

  sendDefaultPii: true,

  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0,

  beforeSend(event, _hint) {
    if (isCheckly(event)) return null
    return event
  },
  beforeSendTransaction(event, hint) {
    if (isCheckly(event)) return null
    return event
  },
}
