export interface OGUrlParams {
  urlBase?: string
  needId: number
  imageCdnBase?: string
  orgImagePath?: string
  orgTitle?: string
  needTitle?: string
  needDescription?: string
  needPrice?: string
  needLocation?: string
}

export const getOpenGraphNeedUrl = (
  {
    urlBase = 'https://og-image.purposity.app',
    needId,
    imageCdnBase,
    orgImagePath,
    orgTitle,
    needTitle,
    needDescription,
    needPrice,
    needLocation,
  }: OGUrlParams,
  options?: { primeCache: boolean }
) => {
  const fileType = 'png'

  const url = new URL(`/need_${needId}.${fileType}`, urlBase)

  url.searchParams.set('md', `1`)
  url.searchParams.set('fontSize', '75px')

  imageCdnBase &&
    orgImagePath &&
    url.searchParams.set(
      'orgImage',
      `${imageCdnBase}/cdn-cgi/image/height=96,metadata=none,fit=cover,format=auto,width=96,quality=100/${orgImagePath}`
    )
  orgTitle && url.searchParams.set('orgTitle', orgTitle)
  needTitle && url.searchParams.set('needTitle', needTitle)
  needDescription && url.searchParams.set('needDescription', needDescription)
  needPrice && url.searchParams.set('needPrice', needPrice)
  needLocation && url.searchParams.set('needLocation', needLocation)

  // cache bust: adjust value to force new image to be generated
  url.searchParams.set('c', '1')

  const ogUrl = url.toString()
  if (options?.primeCache) primeImageCache(ogUrl)

  return ogUrl
}

async function primeImageCache(openGraphUrl: string) {
  try {
    await fetch(openGraphUrl)
    console.log(`primed cache for open graph image: ${openGraphUrl}`)
  } catch {
    console.log(`failed to prime cache for open graph image: ${openGraphUrl}`)
  }
}
