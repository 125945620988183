import { withBucket } from '../../lib/createBucket'
import create_ from './create_'
import expire_ from './expire_'

export default withBucket((bucket) => {
  return {
    create: create_(bucket),
    expire: expire_(bucket),
  }
})
