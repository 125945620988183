import * as z from 'zod'

/**
 * degreees north or south of the Equator
 * @example 38.895
 */
export const Latitude = z
  .number()
  .or(z.string())
  .transform((value) => Number(value))
  .refine((arg) => arg > -90 && arg < 90, 'Latitude must be between -90 and 90')

/**
 * degrees east or west of the Prime Meridian
 * @example -77.036
 */
export const Longitude = z
  .number()
  .or(z.string())
  .transform((value) => Number(value))
  .refine(
    (arg) => arg > -180 && arg < 180,
    'Longitude must be between -180 and 180'
  )

export const Coordinates = z.tuple([Longitude, Latitude])

export const GeoPoint = z.object({
  type: z.literal('Point'),
  coordinates: Coordinates,
})

Coordinates.transform((val) => {
  return {
    type: z.literal('Point'),
    coordinates: val,
  }
})

type Miles = number
type Meters = number

function milesToMeters(miles: Miles): Meters {
  return Math.floor(((1000 * 8) / 5) * miles)
}

export const makeDistance = ({
  distance,
  latitude,
  longitude,
}: {
  /**
   * distance in miles
   * @example 5
   */
  distance: Miles
  latitude: z.infer<typeof Latitude>
  longitude: z.infer<typeof Longitude>
}) => ({
  _st_d_within: {
    distance: milesToMeters(distance),
    from: GeoPoint.parse([longitude, latitude]),
  },
})
