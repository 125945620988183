import * as bitfun from 'bitfun'
import * as z from 'zod'
import { Role } from './roles'

export const RolesMask = z.object({
  [Role.enum.public]: z.literal(0),
  [Role.enum.user]: z.literal(1),
  [Role.enum.organizer]: z.literal(2),
  [Role.enum.admin]: z.literal(4),
  [Role.enum.org_admin]: z.literal(8),
})
export type RolesMask = z.infer<typeof RolesMask>

export const ROLES_MASK: RolesMask = {
  [Role.enum.public]: 0,
  [Role.enum.user]: 1,
  [Role.enum.organizer]: 2,
  [Role.enum.admin]: 4,
  [Role.enum.org_admin]: 8,
} as const

const ReversedRolesMask = z.map(z.number(), Role)
type ReversedRolesMask = z.infer<typeof ReversedRolesMask>

const reversedRolesMask: ReversedRolesMask = new Map(
  Object.entries(ROLES_MASK).map(([k, v]) => [v, k as Role])
)

export const Roles = z.array(Role)
export type Roles = z.infer<typeof Roles>

const RolesMaskInt = z.coerce.number().int().nonnegative()
export type RolesMaskInt = z.infer<typeof RolesMaskInt>

export const rolesMaskToNamedRoles = z
  .function()
  .args(RolesMaskInt)
  .returns(Roles)
  .implement((rolesMaskInt) => {
    const intRoles = bitfun.toArray(rolesMaskInt)

    const namedRoles = intRoles.flatMap((role) =>
      reversedRolesMask.has(role) ? [reversedRolesMask.get(role)] : []
    )
    return Roles.parse(namedRoles)
  })

export const RolesFromRolesMask = z
  .preprocess((v) => {
    if (typeof v === 'number') return rolesMaskToNamedRoles(v)
    return v
  }, Roles)
  .transform((v) => [Role.enum.user_public, ...v])
  // .refine((v) => Roles.safeParse(v).success, 'Invalid roles')
  // .transform(v=>Roles.parse(v))
  .transform((v, ctx) => {
    const parsed = Roles.safeParse(v)
    if (!parsed.success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid roles',
      })
      return z.NEVER
    }
    return parsed.data
  })
export type RolesFromRolesMask = z.infer<typeof RolesFromRolesMask>

export function processRolesMask(rolesMask: number, orgId?: number) {
  return {
    isUser: bitfun.includes(rolesMask, ROLES_MASK[Role.enum.user]),
    isOrganizer: bitfun.includes(rolesMask, ROLES_MASK[Role.enum.organizer]),
    isOrgAdmin: bitfun.includes(rolesMask, ROLES_MASK[Role.enum.org_admin]),
    isAdmin: bitfun.includes(rolesMask, ROLES_MASK[Role.enum.admin]),
    isSuperAdmin:
      bitfun.includes(rolesMask, ROLES_MASK[Role.enum.admin]) && orgId === 27,
  }
}

export function rolesToMask(roles: Roles) {
  const trimmed: Exclude<Role, 'user_public' | 'super_admin'>[] = []
  for (const role of roles) {
    if (role === Role.enum.user_public) {
      trimmed.push(Role.enum.user)
    } else if (role === Role.enum.super_admin) {
      trimmed.push(Role.enum.admin)
    } else {
      trimmed.push(role)
    }
  }
  return bitfun.fromArray(trimmed.map((role) => ROLES_MASK[role]))
}

export const RolesMaskAsInt = RolesMaskInt.or(Roles)
  .transform((v) => {
    if (typeof v === 'number') return v
    return rolesToMask(v)
  })
  .catch(0)
export type RolesMaskAsInt = z.infer<typeof RolesMaskAsInt>
