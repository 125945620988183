import * as z from 'zod'

export const ValidId = z
  .union([z.number(), z.string(), z.array(z.string())])
  .transform((value) => {
    if (Array.isArray(value)) {
      return value.join(',')
    }
    return value
  })
  .transform((value) => Number(value))
  .refine((arg) => Number.isInteger(arg), 'ID must be integer')
  .refine((arg) => arg > 0, 'ID must be positive')
  .describe('Valid Purposity ID')

export type ValidId = z.infer<typeof ValidId>
