import * as z from 'zod'
import { Role as LegacyRole } from '@purposity/utils'
import { Roles } from '~/lib/clerk/authorization'

export const StripeCustomerId = z.string().startsWith('cus_')

export type StripeCustomerId = z.infer<typeof StripeCustomerId>

export const ClerkId = z.string().startsWith('user_')
export type ClerkId = z.infer<typeof ClerkId>

export const combinedRoles = LegacyRole.or(Roles)

export const orgId = z.string().startsWith('org_')

export const sessionId = z.string().startsWith('sess_')
