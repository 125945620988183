import { IdSchema } from 'utils-schemas'
import * as z from 'zod'
import type { JwtPayload } from '@clerk/types'
import {
  ClerkId,
  combinedRoles,
  orgId,
  sessionId,
  StripeCustomerId,
} from '~/lib/clerk/_shared'
import { Permissions, Roles } from '~/lib/clerk/authorization'
import {
  OrgEntityType,
  PurposityOrgMembershipPublicMetadata,
  PurposityOrgPublicMetadata,
} from '~/lib/clerk/metadata.organization'

export const TEMPLATE_NAME = '__session' as const

/**
  * `admin-panel` template
  * ```json
    {
      "purposity": {
        "sid": "{{user.public_metadata.stripe_customer_id}}",
        "uid": "{{user.public_metadata.purposity_id}}",
        "orgs": "{{user.organizations}}",
        "roles": [
          "user_public",
          "user"
        ],
        "org_id": "{{org.id}}",
        "org_name": "{{org.name}}",
        "org_role": "{{org.role}}",
        "org_slug": "{{org.slug}}",
        "org_type": "{{org.public_metadata.entity.type}}",
        "session_actor": "{{session.actor}}",
        "org_mem_pub_md": "{{org_membership.public_metadata}}",
        "org_pub_metadata": "{{org.public_metadata}}"
      }
    }
    ```
  * @example
  * {
      "azp": "http://localhost:3020",
      "exp": 1721625035,
      "iat": 1721624975,
      "iss": "https://clerk.feasible.herring-93.lcl.dev",
      "jti": "8c2100901168495e335d",
      "nbf": 1721624970,
      "purposity": {
        "org_id": "org_2jaCIxW7NjlwefOU33oTTXfGJyX",
        "org_mem_pub_md": {},
        "org_name": "Purposity",
        "org_pub_metadata": {
          "entity": {
            "type": "administrative"
          }
        },
        "org_role": "org:purposity_admin",
        "org_slug": "purposity-team",
        "org_type": "administrative",
        "orgs": {
          "org_2QqEzzTrZXtqQvE8faiBLPAtXom": "org:purposity_admin",
          "org_2XUXfYLzrX8fyUieNF0SCv6ZyBe": "org:purposity_admin",
          "org_2XVtJfYz7jjvDfsy63HNM0Feavd": "org:purposity_admin",
          "org_2XaOzBVttUxAm3tmchlYbeDP2kz": "org:organizer",
          "org_2jaCIxW7NjlwefOU33oTTXfGJyX": "org:purposity_admin"
        },
        "roles": [
          "user_public",
          "user"
        ],
        "session_actor": null,
        "sid": "cus_O1qioFjhFXVHKh",
        "uid": "117"
      },
      "sid": "{{session.id}}",
      "sub": "user_2Qo8DRIoRM1Wx6BxBX87IpZsuXM"
    }
 */

export const extendedClaims = z.object({
  purposity: z.object({
    sid: StripeCustomerId,
    uid: IdSchema,
    orgs: z.record(orgId, Roles),
    oid: IdSchema,
    roles: combinedRoles.array(),
    org_id: orgId,
    org_name: z.string().min(1),
    org_role: Roles,
    org_type: OrgEntityType,
    session_actor: z
      .object({
        iss: z.string(),
        sid: sessionId,
        sub: ClerkId,
      })
      .nullable(),
    org_mem_pub_md: PurposityOrgMembershipPublicMetadata,
    org_pub_metadata: PurposityOrgPublicMetadata,
    role: combinedRoles,
  }),
  org_slug: z.string().min(1),
  org_permissions: Permissions.array(),
})

export type ExtendedClaims = Prettify<z.infer<typeof extendedClaims>>
