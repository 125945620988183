import isFQDN from 'validator/es/lib/isFQDN'
import * as z from 'zod'

export const Domain = z.string().refine((v) => isFQDN(v))

export const UrlHostname = z
  .string()
  .url()
  .transform((v) => new URL(v))
  .transform((v) => v.hostname)

export const Hostname = z.union([Domain, UrlHostname])

export const RE_PURPOSITY_HOSTNAME = /(?:[\w\d-_]+\.)?purposity\.(?:com|app)/i

export const PurposityHostname = z.string().regex(RE_PURPOSITY_HOSTNAME)
