import type { MyAnalyticsInstance } from '..'
import type { PluginsCreator } from '../analytics.types.plugins'
import type { EventMap } from '../purposity-config/track'
import type { AnalyticsInstanceAny } from 'analytics'
import { captureException, setExtra, setTag } from '~/universal/sentry'

type PluginConfig<
  TAnalyticsInstance extends AnalyticsInstanceAny = AnalyticsInstanceAny
> = {
  __shape?: TAnalyticsInstance['_shape']
  eventMap: typeof EventMap
}

export const eventValidationPlugin: PluginsCreator.Create<
  PluginConfig<MyAnalyticsInstance>,
  MyAnalyticsInstance
> = (pluginConfig) => {
  return {
    name: 'purposity-event-validation-plugin',
    config: pluginConfig,
    trackStart: ({ payload, abort, config }) => {
      const eventName = payload.event

      const validKey = config.eventMap.keyof().safeParse(eventName)
      if (!validKey.success) {
        const message = `Event name "${payload.event}" did not pass validation. Event name is not in the event map.`
        const err = new Error(message, {
          cause: validKey.error,
        })
        console.warn(err.message)
        setTag('feature', 'analytics')
        setExtra('payload', payload)
        captureException(err)
        return abort(message)
      }
    },
  }
}
