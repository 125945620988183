export function formatDisplayName(
  username: string,
  firstName?: string,
  lastName?: string
) {
  return firstName
    ? lastName
      ? `${firstName} ${lastName}`
      : `${firstName}`
    : username
}
