import * as z from 'zod'

const ErrorCodes = {
  INVALID_TOKEN: 'purposity/invalid-token',
  INVALID_CLAIMS: 'purposity/invalid-claims',
  UNKNOWN: 'purposity/unknown',
} as const

const ErrorCode = z.nativeEnum(ErrorCodes)
type ErrorCode = z.infer<typeof ErrorCode>

export class AuthError extends Error {
  constructor(public code: ErrorCode, public options?: ErrorOptions) {
    super('PurposityAuthError', options)
  }

  static codes = ErrorCodes
  static INVALID_TOKEN = this.codes.INVALID_TOKEN
  static INVALID_CLAIMS = this.codes.INVALID_CLAIMS
  static UNKNOWN = this.codes.UNKNOWN
  static isAuthError = (err: unknown): err is AuthError =>
    err instanceof AuthError
}
