import {
  resolveRequestDocument,
  type GraphQLClient,
  type RequestDocument,
  type ClientError,
  type RawRequestOptions,
  type RequestOptions,
} from 'graphql-request'
import type { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'
import type { GraphQLError } from 'graphql'

export type CustomAbortSignal = NonNullable<RequestInit['signal']>

/**
 * @description: takes a TypedDocumentNode (i.e.,: gql(`***`)) as an argument and (after running codegen) returns a correctly typed promise that resolves to the result of the query
 */
export function makeQueryFn<TRequestDocument extends RequestDocument>(
  document: TRequestDocument,
  api: GraphQLClient
): (
  variables: VariablesOf<TRequestDocument>,
  requestHeaders?: RequestInit['headers'],
  signal?: RequestInit['signal']
) => Promise<ResultOf<TRequestDocument>> {
  return (variables, requestHeaders, signal) => {
    const resolvedRequestDocument = resolveRequestDocument(document)
    const mergedHeaders = parseHeaders(requestHeaders)

    if (resolvedRequestDocument.operationName) {
      mergedHeaders.set(
        'x-graphql-operation-name',
        resolvedRequestDocument.operationName
      )
    }

    return api.request<ResultOf<TRequestDocument>>({
      document,
      variables,
      requestHeaders: mergedHeaders,
      signal: signal as CustomAbortSignal,
    } as RequestOptions)
  }
}

type SafeParseSuccess<Output> = {
  success: true
  data: Output
}
type SafeParseError<Input> = {
  success: false
  error: Zod.ZodError<Input> | Error | ClientError
}
type SafeParseReturnType<Input, Output> =
  | SafeParseSuccess<Output>
  | SafeParseError<Input>

export function makeSafeQueryFn<TRequestDocument extends RequestDocument>(
  document: TRequestDocument,
  api: GraphQLClient
): (
  variables: VariablesOf<TRequestDocument>,
  requestHeaders?: RequestInit['headers'],
  signal?: RequestInit['signal']
) => Promise<
  SafeParseReturnType<VariablesOf<TRequestDocument>, ResultOf<TRequestDocument>>
> {
  return async (variables, requestHeaders, signal) => {
    try {
      const resolvedRequestDocument = resolveRequestDocument(document)

      const mergedHeaders = parseHeaders(requestHeaders)

      if (resolvedRequestDocument.operationName) {
        mergedHeaders.set(
          'x-graphql-operation-name',
          resolvedRequestDocument.operationName
        )
      }

      const req = await api.request<ResultOf<TRequestDocument>>({
        document,
        variables,
        requestHeaders: mergedHeaders,
        signal: signal as CustomAbortSignal,
      } as RequestOptions)

      return {
        success: true,
        data: req,
      }
    } catch (error) {
      if (error instanceof Error) {
        return {
          success: false,
          error,
        }
      } else {
        throw error
      }
    }
  }
}

type RawRequestResult<T = any> = {
  data: T
  extensions?: any
  headers: RequestInit['headers']
  errors?: GraphQLError[]
  status: number
}

export function makeRawQueryFn<TRequestDocument extends RequestDocument>(
  document: TRequestDocument,
  api: GraphQLClient
): (
  variables: VariablesOf<TRequestDocument>,
  requestHeaders?: RequestInit['headers'],
  signal?: RequestInit['signal']
) => Promise<RawRequestResult<ResultOf<TRequestDocument>>> {
  return (variables, requestHeaders, signal) => {
    const resolvedRequestDocument = resolveRequestDocument(document)
    const mergedHeaders = parseHeaders(requestHeaders)

    if (resolvedRequestDocument.operationName) {
      mergedHeaders.set(
        'x-graphql-operation-name',
        resolvedRequestDocument.operationName
      )
    }

    return api.rawRequest({
      query: resolvedRequestDocument.query,
      variables,
      requestHeaders: mergedHeaders,
      signal: signal as CustomAbortSignal,
    } as RawRequestOptions)
  }
}

export function makeRawSafeQueryFn<TRequestDocument extends RequestDocument>(
  document: TRequestDocument,
  api: GraphQLClient
): (
  variables: VariablesOf<TRequestDocument>,
  requestHeaders?: RequestInit['headers'],
  signal?: RequestInit['signal']
) => Promise<
  SafeParseReturnType<
    VariablesOf<TRequestDocument>,
    RawRequestResult<ResultOf<TRequestDocument>>
  >
> {
  return async (variables, requestHeaders, signal) => {
    try {
      const resolvedRequestDocument = resolveRequestDocument(document)
      const mergedHeaders = parseHeaders(requestHeaders)

      if (resolvedRequestDocument.operationName) {
        mergedHeaders.set(
          'x-graphql-operation-name',
          resolvedRequestDocument.operationName
        )
      }
      const req = await api.rawRequest<ResultOf<TRequestDocument>>({
        query: resolvedRequestDocument.query,
        variables,
        requestHeaders: mergedHeaders,
        signal: signal as CustomAbortSignal,
      } as RawRequestOptions)

      return {
        success: true,
        data: req,
      }
    } catch (error) {
      if (error instanceof Error) {
        return {
          success: false,
          error,
        }
      } else {
        throw error
      }
    }
  }
}

function parseHeaders(requestHeaders: HeadersInit | undefined) {
  return new Headers(requestHeaders)
}
