import * as z from 'zod'

export type InputType<DefaultType extends z.ZodTypeAny> = {
  (): z.ZodEffects<DefaultType>
  <ProvidedType extends z.ZodTypeAny>(
    schema: ProvidedType
  ): z.ZodEffects<ProvidedType>
}

const stripEmpty = z.literal('').transform(() => undefined)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const oldPreprocessIfValid = (schema: z.ZodTypeAny) => (val: unknown) => {
  const result = schema.safeParse(val)
  if (result.success) return result.data
  return val
}

function preprocessIfValid<
  TPreSchema extends z.ZodTypeAny,
  TActualSchema extends z.ZodTypeAny
>(preSchema: TPreSchema, actualSchema: TActualSchema) {
  return z.preprocess((val) => {
    const result = preSchema.safeParse(val)
    if (result.success) return result.data
    return val
  }, actualSchema)
}

/**
 * Transforms any empty strings to `undefined` before validating.
 * This makes it so empty strings will fail required checks,
 * allowing you to use `optional` for optional fields instead of `nonempty` for required fields.
 * If you call `zfd.text` with no arguments, it will assume the field is a required string by default.
 * If you want to customize the schema, you can pass that as an argument.
 */
export const text = (schema = z.string()) =>
  preprocessIfValid(stripEmpty, schema)

/**
 * Coerces numerical strings to numbers transforms empty strings to `undefined` before validating.
 * If you call `zfd.number` with no arguments,
 * it will assume the field is a required number by default.
 * If you want to customize the schema, you can pass that as an argument.
 */
export const numeric = (schema = z.number()) =>
  preprocessIfValid(
    z.union([
      stripEmpty,
      z
        .string()
        .transform((val) => Number(val))
        .refine((val) => !Number.isNaN(val)),
    ]),
    schema
  )

type CheckboxOpts = {
  trueValue?: string
}

/**
 * Turns the value from a checkbox field into a boolean,
 * but does not require the checkbox to be checked.
 * For checkboxes with a `value` attribute, you can pass that as the `trueValue` option.
 *
 * @example
 * ```ts
 * const schema = zfd.formData({
 *   defaultCheckbox: zfd.checkbox(),
 *   checkboxWithValue: zfd.checkbox({ trueValue: "true" }),
 *   mustBeTrue: zfd
 *     .checkbox()
 *     .refine((val) => val, "Please check this box"),
 *   });
 * });
 * ```
 */
export const checkbox = ({ trueValue = 'on' }: CheckboxOpts = {}) =>
  z.union([
    z.literal(trueValue).transform(() => true),
    z.literal(undefined).transform(() => false),
  ])

const stripNoFile = z.unknown().transform((val) => {
  return val instanceof File && val.size === 0 ? undefined : val
})

export const file = (schema = z.instanceof(File)) =>
  preprocessIfValid(stripNoFile, schema)

const alwaysArray = z.unknown().transform((val: unknown): any[] => {
  if (Array.isArray(val)) return val
  if (val === undefined) return []
  return [val]
})
/**
 * Preprocesses a field where you expect multiple values could be present for the same field name
 * and transforms the value of that field to always be an array.
 * If you don't provide a schema, it will assume the field is an array of zfd.text fields
 * and will not require any values to be present.
 */
export const repeatable = (schema = z.array(text())) =>
  preprocessIfValid(alwaysArray, schema)

/**
 * A convenience wrapper for repeatable.
 * Instead of passing the schema for an entire array, you pass in the schema for the item type.
 */
export const repeatableOfType = (schema: unknown) => {
  throw new Error('Not implemented')
}

const entries = z.array(z.tuple([z.string(), z.any()]))

export type FormDataType = {
  <T extends z.ZodRawShape>(shape: T): z.ZodEffects<z.ZodObject<T>>
  <T extends z.ZodTypeAny>(schema: T): z.ZodEffects<T>
}

const safeParseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString)
  } catch {
    return jsonString
  }
}

export const json = <T extends z.ZodTypeAny>(schema: T): z.ZodEffects<T> =>
  preprocessIfValid(
    z.union([stripEmpty, z.string().transform((val) => safeParseJson(val))]),
    schema
  )

const processFormData =
  // We're avoiding using `instanceof` here because different environments
  // won't necessarily have `FormData` or `URLSearchParams`
  z
    .any()
    .refine((val) => Symbol.iterator in val)
    .transform((val) => [...val])
    .refine(
      (val): val is z.infer<typeof entries> => entries.safeParse(val).success
    )

    .transform((data) => {
      const map = new Map<string, any[]>()
      for (const [key, value] of data) {
        if (map.has(key)) {
          map.get(key)!.push(value)
        } else {
          map.set(key, [value])
        }
      }

      return [...map.entries()].reduce((acc, [k, v]) => {
        return {
          ...acc,
          [k]: v.length === 1 ? v[0] : v,
        }
      }, {})
    })
    .transform((d) => d as Record<string, unknown>)

export type PreProcessFormData = (val: unknown) => Record<string, unknown>
export const preprocessFormData = (formData: unknown) =>
  processFormData.parse(formData)

/**
 * This helper takes the place of the `z.object` at the root of your schema.
 * It wraps your schema in a `z.preprocess` that extracts all the data out of a `FormData`
 * and transforms it into a regular object.
 * If the `FormData` contains multiple entries with the same field name,
 * it will automatically turn that field into an array.
 */
export function formData<TSchema extends z.ZodType<object, any, any>>(
  schema: TSchema
) {
  return preprocessIfValid(processFormData, schema)
}

export function formDataRaw<TShape extends z.ZodRawShape>(shape: TShape) {
  return preprocessIfValid(processFormData, z.object(shape))
}

export function mergedFormData(
  schemaOrShape: z.ZodRawShape | z.ZodType<any, any, any>
) {
  return schemaOrShape instanceof z.ZodType
    ? formData(schemaOrShape)
    : formDataRaw(schemaOrShape)
}

// const testItem = { car: text(), tree: z.boolean() }

// const FormRaw = formDataRaw(testItem)
// const _formRaw = FormRaw.parse({ car: 'Ford' })
// const parsedRaw = _formRaw.car

// const FormAlt = formData(z.object(testItem))
// const _formalt = FormAlt.parse({ car: 'Ford' })
// const parsedAlt = _formalt.tree
