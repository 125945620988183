import * as z from 'zod'

export const BBoxSchema = z.array(z.number()).min(4).max(6)
export type BBox = z.infer<typeof BBoxSchema>

export const PositionSchema = z.array(z.number()).min(2).max(3)
export type Position = z.infer<typeof PositionSchema>

export const GeoJsonTypes = z.enum([
  'Point',
  'MultiPoint',
  'LineString',
  'MultiLineString',
  'Polygon',
  'MultiPolygon',
  'GeometryCollection',
  'Feature',
  'FeatureCollection',
])
export type GeoJsonTypes = z.infer<typeof GeoJsonTypes>

export const GeoJsonObjectSchema = z.object({
  type: GeoJsonTypes,
  bbox: BBoxSchema.optional(),
})

export const PointSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.Point),
  coordinates: PositionSchema,
})
export type Point = z.infer<typeof PointSchema>

export const MultiPointSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.MultiPoint),
  coordinates: z.array(PositionSchema),
})
export type MultiPoint = z.infer<typeof MultiPointSchema>

export const LineStringSchema = z.lazy(() =>
  GeoJsonObjectSchema.extend({
    type: z.literal(GeoJsonTypes.Values.LineString),
    coordinates: z.array(PositionSchema),
  })
)
export type LineString = z.infer<typeof LineStringSchema>

export const MultiLineStringSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.MultiLineString),
  coordinates: z.array(z.array(PositionSchema)),
})
export type MultiLineString = z.infer<typeof MultiLineStringSchema>

export const PolygonSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.Polygon),
  coordinates: z.array(z.array(PositionSchema)),
})
export type Polygon = z.infer<typeof PolygonSchema>

export const MultiPolygonSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.MultiPolygon),
  coordinates: z.array(z.array(z.array(PositionSchema))),
})
export type MultiPolygon = z.infer<typeof MultiPolygonSchema>

export type GeometryCollection = z.infer<typeof GeoJsonObjectSchema> & {
  geometries: Geometry[]
}
const GeometryCollectionSchema: z.ZodType<GeometryCollection> =
  GeoJsonObjectSchema.extend({
    type: z.literal(GeoJsonTypes.Values.GeometryCollection),
    geometries: z.lazy(() => GeometrySchema.array()),
  })

export const GeoJsonPropertiesSchema = z.record(z.string(), z.any()).nullable()
export type GeoJsonProperties = z.infer<typeof GeoJsonPropertiesSchema>

export const GeometrySchema = z.union([
  PointSchema,
  MultiPointSchema,
  LineStringSchema,
  MultiLineStringSchema,
  PolygonSchema,
  MultiPolygonSchema,
  GeometryCollectionSchema,
])
export type Geometry = z.infer<typeof GeometrySchema>

export const GeoJsonGeometryTypesSchema = GeoJsonTypes.extract([
  'Point',
  'MultiPoint',
  'LineString',
  'MultiLineString',
  'Polygon',
  'MultiPolygon',
  'GeometryCollection',
])
export type GeoJsonGeometryTypes = z.infer<typeof GeoJsonGeometryTypesSchema>

export const FeatureSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.Feature),
  geometry: GeometrySchema.nullable(),
  id: z.union([z.string(), z.number()]).optional(),
  properties: z.record(z.unknown()).nullable(),
})
export type Feature = z.infer<typeof FeatureSchema>

export const FeatureCollectionSchema = GeoJsonObjectSchema.extend({
  type: z.literal(GeoJsonTypes.Values.FeatureCollection),
  features: z.array(FeatureSchema),
})
export type FeatureCollection = z.infer<typeof FeatureCollectionSchema>

export const GeoJSONSchema = z.union([
  GeometrySchema,
  FeatureSchema,
  FeatureCollectionSchema,
])
export type GeoJSON = z.infer<typeof GeoJSONSchema>

export const Schema = GeoJSONSchema
export type Schema = GeoJSON
