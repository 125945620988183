import { Platform } from 'react-native'
import { appConfig } from 'config'
import { createRootLogger } from 'logger'
import * as z from 'zod'
import { getPurpositySdk } from '@purposity/sdk'
import { sdkFnWrapper } from '../util/sdkFnWrapper'
import { handleSdkErrors } from './shared-handle-errors'
import type { HeadlessBrowserClerk } from '@clerk/clerk-react'
import { setExtra, setTag } from '~/universal/sentry'

const log = createRootLogger('sdk-service-user', {
  enabled: false,
})

declare global {
  let Clerk: HeadlessBrowserClerk | null | undefined
}

async function getApiToken() {
  if (!global.Clerk?.session) {
    log.warn('Could not get API token')
    return null
  }

  const getToken = global.Clerk.session.getToken
  const token = await getToken({
    skipCache: false,
    leewayInSeconds: 10,
  })

  return token
}

async function getAuthHeaders() {
  if (typeof global.Clerk?.session === 'undefined') {
    // not loaded
    return headersFromNative(false, undefined, undefined)
  } else if (global.Clerk.session === null) {
    // signed out
    return headersFromNative(true, false, null)
  } else {
    // signed in
    return headersFromNative(true, true, await getApiToken())
  }
}

export const sdk = getPurpositySdk({
  gql: {
    identifier: 'user-api',
    origin: appConfig.computedApiURL,
    clientOrigin: appConfig.deploymentURL.origin,
    purposityEnv: appConfig.purposityEnv,
    appName: appConfig.appName,
    clientOptions: {
      keepalive: true,
      credentials: 'include',
      async requestMiddleware(request) {
        const headers = new Headers(request.headers)

        if (Platform.OS === 'web') {
          headers.set('x-purposity-auth-result', 'cookie')
          headers.set('x-purposity-auth-source', 'use-auth-headers')
        } else {
          const gatewayActionOrigin =
            'http://' +
            (await import('expo-application').then((mod) => mod.applicationId))
          headers.set('x-gateway-action-origin', gatewayActionOrigin)

          const additionalAuthHeaders = await getAuthHeaders()
          if (additionalAuthHeaders['x-purposity-auth-result'] === 'token') {
            headers.set('authorization', additionalAuthHeaders.authorization)
          }
          headers.set(
            'x-purposity-auth-result',
            additionalAuthHeaders['x-purposity-auth-result']
          )
          headers.set(
            'x-purposity-auth-source',
            additionalAuthHeaders['x-purposity-auth-source']
          )
        }

        const operationType = headers.get('x-graphql-operation-type')
        const operationName = headers.get('x-graphql-operation-name')
        setTag('kind', operationType)
        setExtra('name', operationName)
        // setTransactionName(operationName!)
        // withScope(async (scope) => {

        //   if (request.body) {
        //     if (request.body instanceof ReadableStream) {
        //       const requestCopy = new Request('request', request)
        //       const json = await requestCopy.json()
        //       scope.setContext('GraphQLClient', {
        //         operationName: json.operationName,
        //         variables: json.variables,
        //         extensions: json.extensions,
        //       })
        //     } else if (typeof request.body === 'string') {
        //     } else if (request.body instanceof Blob) {
        //     } else if (request.body instanceof FormData) {
        //     } else if (request.body instanceof URLSearchParams) {
        //     } else {
        //       request.body
        //     }
        //   }
        // })
        request.headers = headers
        return request
      },
      responseMiddleware(response) {
        handleSdkErrors(response)
      },
    },
    withWrapper: sdkFnWrapper,
  },
})

const AuthHeadersBase = z.object({
  'x-purposity-auth-source': z.literal('use-auth-headers'),
})

const AuthHeaders = z.discriminatedUnion('x-purposity-auth-result', [
  AuthHeadersBase.extend({
    'x-purposity-auth-result': z.literal('cookie'),
  }),
  AuthHeadersBase.extend({
    authorization: z.string(),
    'x-purposity-auth-result': z.literal('token'),
  }),
  AuthHeadersBase.extend({
    'x-purposity-auth-result': z.literal('no-token'),
  }),
  AuthHeadersBase.extend({
    'x-purposity-auth-result': z.literal('not-signed-in'),
  }),
  AuthHeadersBase.extend({
    'x-purposity-auth-result': z.literal('not-loaded'),
  }),
])
type AuthHeaders = z.infer<typeof AuthHeaders>

function headersFrom(
  isLoaded: boolean,
  isSignedIn: undefined | false | true,
  token: undefined | null | string
): AuthHeaders {
  if (Platform.OS === 'web') {
    return {
      'x-purposity-auth-result': 'cookie',
      'x-purposity-auth-source': 'use-auth-headers',
    } as const
  }
  return headersFromNative(isLoaded, isSignedIn, token)
}

type WithoutCookieHeaders<TAuthHeaders extends AuthHeaders> =
  TAuthHeaders extends {
    'x-purposity-auth-result': 'cookie'
  }
    ? never
    : TAuthHeaders

function headersFromNative(
  isLoaded: boolean,
  isSignedIn: undefined | false | true,
  token: undefined | null | string
): WithoutCookieHeaders<AuthHeaders> {
  if (!isLoaded) {
    return {
      'x-purposity-auth-result': 'not-loaded',
      'x-purposity-auth-source': 'use-auth-headers',
    } as const
  }

  if (!isSignedIn) {
    return {
      'x-purposity-auth-result': 'not-signed-in',
      'x-purposity-auth-source': 'use-auth-headers',
    } as const
  }

  if (!token) {
    return {
      'x-purposity-auth-result': 'no-token',
      'x-purposity-auth-source': 'use-auth-headers',
    } as const
  }

  return {
    authorization: `Bearer ${token}`,
    'x-purposity-auth-result': 'token',
    'x-purposity-auth-source': 'use-auth-headers',
  } as const
}
