import { withBucket } from '../../lib/createBucket'
import cancel_ from './cancel_'
import create_ from './create_'

export default withBucket((bucket) => {
  return {
    create: create_(bucket),
    cancel: cancel_(bucket),
  }
})
