import * as React from 'react'
import { backgroundGray, darkBlue, neutralGray, purple } from '@themes/Colors'

export type Variants = 'default' | 'purple' | 'gray' | 'neutral_gray'

type State = { bgColor: string; logoType: 'gray' | 'primary' }

type Action = { type: 'SET_VARIANT'; variant: Variants }
type Dispatch = (action: Action) => void
type FullScreenLayoutProviderProps = React.PropsWithChildren<{
  initVariant?: Variants
}>

const FullScreenLayout = React.createContext<
  | {
      state: State
      dispatch: Dispatch
    }
  | undefined
>(undefined)

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_VARIANT': {
      return { ...state, ...variantToState(action.variant) }
    }
    default: {
      const _exhaustiveCheck: never = action.type
      return _exhaustiveCheck
    }
  }
}

export function variantToState(variant: Variants): State {
  switch (variant) {
    case 'default': {
      return { bgColor: darkBlue, logoType: 'primary' }
    }
    case 'purple': {
      return { bgColor: purple, logoType: 'gray' }
    }
    case 'gray': {
      return { bgColor: backgroundGray, logoType: 'primary' }
    }
    case 'neutral_gray': {
      return { bgColor: neutralGray, logoType: 'primary' }
    }
    default: {
      const _exhaustiveCheck: never = variant
      return _exhaustiveCheck
    }
  }
}

function FullScreenLayoutProvider({
  children,
  initVariant = 'default',
}: FullScreenLayoutProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initVariant, (init) => {
    return variantToState(init)
  })

  const value = { state, dispatch }
  return (
    <FullScreenLayout.Provider value={value}>
      {children}
    </FullScreenLayout.Provider>
  )
}

function useFullScreenLayout() {
  const context = React.useContext(FullScreenLayout)
  if (context === undefined) {
    throw new Error(
      'useFullScreenLayout must be used within a FullScreenLayoutProvider'
    )
  }
  return context
}

export { FullScreenLayoutProvider, useFullScreenLayout }
