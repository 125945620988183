export const GET_PLACEHOLDER_GEO = () => {
  return {
    latitude: 39.5837,
    longitude: -104.9627,
    city: 'Centennial',
    region: 'CO',
    country: 'US',
    point: {
      type: 'Point',
      coordinates: [-104.9626, 39.5836],
    },
  }
}
