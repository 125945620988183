import { IdSchema } from 'utils-schemas'
import * as z from 'zod'

export const OrgEntityType = z.enum(['org', 'team', 'administrative'])

export const PurposityOrgPublicMetadata = z.object({
  entity: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(OrgEntityType.enum.org),
      id: IdSchema,
    }),
    z.object({
      type: z.literal(OrgEntityType.enum.team),
      id: IdSchema,
    }),
    z.object({
      type: z.literal(OrgEntityType.enum.administrative),
    }),
  ]),
})

export type PurposityOrgPublicMetadata = Prettify<
  z.infer<typeof PurposityOrgPublicMetadata>
>

export const PurposityOrgPrivateMetadata = z.object({
  _do_not_use: z.string().nullish(),
})
export type PurposityOrgPrivateMetadata = Prettify<
  z.infer<typeof PurposityOrgPrivateMetadata>
>

export const PurposityOrgMembershipPublicMetadata = z.object({
  _do_not_use: z.string().nullish(),
})
export type PurposityOrgMembershipPublicMetadata = Prettify<
  z.infer<typeof PurposityOrgMembershipPublicMetadata>
>

export const PurposityOrgMembershipPrivateMetadata = z.object({
  _do_not_use: z.string().nullish(),
})
export type PurposityOrgMembershipPrivateMetadata = Prettify<
  z.infer<typeof PurposityOrgMembershipPrivateMetadata>
>
