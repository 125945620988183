import type * as z from 'zod'

type ErrorHandler = <
  TError extends Error = Error,
  TContext extends unknown | {} = {},
>(
  error: TError,
  context?: Partial<TContext>
) => void

interface BuiltObjectModelOptions {
  errorHandler: ErrorHandler
}

export function buildObjectModel<TModel extends z.ZodTypeAny, TResult>(
  model: TModel,
  transformer: (data: z.infer<TModel>) => TResult,
  options: Partial<BuiltObjectModelOptions> = {
    errorHandler: console.error,
  }
) {
  return (data: z.input<TModel>): TResult => {
    const validation = model.safeParse(data, { path: ['builder'] })
    if (!validation.success) {
      options.errorHandler?.(validation.error, { extra: { data } })

      return transformer(data)
    }
    return transformer(validation.data)
  }
}

// : ((data: z.input<TModel>) ) => {
//   return (data: TModelType) => {
//     const validation = model.safeParse(data, { path: ['builder'] })
//     if (!validation.success) {
//       const error = validation.error
//       if (options?.errorHandler) {
//         options.errorHandler(validation.error, { extra: { data } })
//       } else {
//         console.error(error, { extra: { data } })
//       }
//       return transformer(data)
//     }
//     return transformer(validation.data)
//   }
// // }

// const carModel = buildObjectModel(
//   z
//     .object({
//       car: z.boolean(),
//       name: z.string().readonly(),
//       color: z.string().optional().default('white'),
//     })
//     .transform((data) => {
//       const vehicleType = data.car ? 'car' : ('truck' as const)
//       const { car, ...rest } = data

//       return {
//         ...rest,
//         type: vehicleType,
//         // get serializedA(): `${typeof this.type}.${typeof vehicleName}` {
//         // return `${this.type}.${this.name}`
//         // },
//       } as const
//     }),
//   (data) => {
//     return {
//       ...data,
//       serialized<T extends string>() {
//         return `${data.type}.${data.name}`
//       },
//     }
//   }
// )

// const car = carModel({ car: true, name: 'Ford' as const })
