/* eslint-disable no-process-env */

import { getPurposityEnv } from 'purposity-env'

export const PURPOSITY_ENV = getPurposityEnv({
  VERCEL_ENV: process.env.VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NODE_ENV: process.env.NODE_ENV,
  DEBUG: process.env.DEBUG,
})

export const WEBHOOK_SECRET_HEADER_KEY = 'x-hasura-webhook-secret'

export const PERMANENT_CLIENT_COOKIE_NAME = '__perm_client'
export const PERMANENT_USER_COOKIE_NAME = '__perm_user'

export const SENTRY_DSN =
  'https://f334a8cde6bd48b68082520c4bc718d5@o546790.ingest.sentry.io/6060573'
