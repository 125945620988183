import isBoolean from 'validator/es/lib/isBoolean'
import * as z from 'zod'

const boolishStringSchema = z
  .string()
  .refine((v) => isBoolean(v, { loose: true }))
  .transform((v, ctx) => {
    switch (v.trim().toLowerCase()) {
      case 'true':
      case 'yes':
      case '1':
        return true
      case 'false':
      case 'no':
      case '0':
      case '':
        return false
      default:
        ctx.addIssue({
          code: 'invalid_literal',
          expected: ['true', 'false', 'yes', 'no', '1', '0', '""'],
          received: v,
          message: `Invalid boolean string literal: ${v}`,
        })
        return false
    }
  })

export const looseBooleanSchema = z.union(
  [
    boolishStringSchema,
    z.boolean(),
    z.literal(1).transform((v) => !!v),
    z.literal(0).transform((v) => !!v),
  ],
  {
    invalid_type_error: 'Invalid boolean literal',
  }
)
export type looseBooleanSchema = z.infer<typeof looseBooleanSchema>
