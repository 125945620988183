import * as z from 'zod'

// source: https://github.com/hasura/graphql-engine/blob/master/server/src-lib/Hasura/Base/Error.hs
enum ErrorCodes {
  // ActionWebhookCode: -> t,
  // CustomCode:  ->  t,
  'AccessDenied' = 'access-denied',
  'AlreadyExists' = 'already-exists',
  'AlreadyTracked' = 'already-tracked',
  'AlreadyUntracked' = 'already-untracked',
  'BadRequest' = 'bad-request',
  'BigQueryError' = 'bigquery-error',
  'Busy' = 'busy',
  'ConcurrentUpdate' = 'concurrent-update',
  'CoercionError' = 'coercion-error',
  'Conflict' = ' conflict',
  'ConstraintError' = 'constraint-error',
  'ConstraintViolation' = 'constraint-violation',
  'CyclicDependency' = 'cyclic-dependency',
  'DataException' = 'data-exception',
  'DependencyError' = 'dependency-error',
  'InvalidConfiguration' = 'invalid-configuration',
  'InvalidHeaders' = 'invalid-headers',
  'InvalidJSON' = 'invalid-json',
  'InvalidParams' = 'invalid-params',
  'JWTInvalid' = 'invalid-jwt',
  'JWTInvalidClaims' = 'jwt-invalid-claims',
  'JWTRoleClaimMissing' = 'jwt-missing-role-claims',
  'MSSQLError' = 'mssql-error',
  'MethodNotAllowed' = 'method-not-allowed',
  'NotExists' = 'not-exists',
  'NotFound' = 'not-found',
  'NotSupported' = 'not-supported',
  'ParseFailed' = 'parse-failed',
  'PermissionDenied' = 'permission-denied',
  'PermissionError' = 'permission-error',
  'PostgresError' = 'postgres-error',
  'PostgresMaxConnectionsError' = 'postgres-max-connections-error',
  'RemoteSchemaConflicts' = 'remote-schema-conflicts',
  'RemoteSchemaError' = 'remote-schema-error',
  'StartFailed' = 'start-failed',
  'Unexpected' = ' unexpected',
  'UnexpectedPayload' = 'unexpected-payload',
  'ValidationFailed' = 'validation-failed',
}

export const HasuraErrorCode = z.nativeEnum(ErrorCodes)
export type HasuraErrorCode = z.infer<typeof HasuraErrorCode>

export const HasuraError = z.object({
  message: z.string(),
  extensions: z.object({
    code: HasuraErrorCode,
    path: z.string().transform((path) => path.split('.')),
  }),
})
export type HasuraError = z.infer<typeof HasuraError>
