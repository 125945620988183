import { Log } from '@services/Logger'
import type { MyAnalyticsInstance } from '..'
import type { PluginsCreator } from '../analytics.types.plugins'
import type { User } from '@sentry/types'
import type { AnalyticsInstanceAny, IdentifyPayload } from 'analytics'

export type PluginConfig<
  TAnalyticsInstance extends AnalyticsInstanceAny = AnalyticsInstanceAny
> = {
  /** sentry.setUser function */
  setUser: (user: User | null) => void
  formatUser: (
    identifyPayload: IdentifyPayload<
      TAnalyticsInstance['_shape']['traits'],
      TAnalyticsInstance['_shape']['identifyOptions']
    >
  ) => User | null
}

/** A future version of this plugin will take generics that will make the pluginConfig aware of the host environment (i.e., traits, eventmap, etc.). The config will then require the developer to define certain getters such as `getEmail` and `getUsername`. These getters will receive a typed `bucket.payload` as a parameter and return the correct value. This will allow the plugin to be reused, no matter what traits are provided in a given environment. (In addition/alternatively, we may be able to use the `analytics` package's custom methods to provide this functionality.). The generics may be even automatically inferred from the `analytics` package's `createAnalytics` method.
 * @example ```
 * type MyHostEnvironment = CreateHostEnvironment<Traits, EventMap, TrackOptions, IdentifyOptions, OtherPayload>;
 *
 * sentryPlugin<MyHostEnvironemt>({
 *  debug: true,
 *  getEmail: (payload) => payload.traits.primary_email_address,
 *  ...
 * })
 * ```
 */
export type CreateSentryPlugin<
  TAnalyticsInstance extends AnalyticsInstanceAny
> = PluginsCreator.Create<PluginConfig<TAnalyticsInstance>, TAnalyticsInstance>

const logger = Log.extend('analytics:sentry')

export const sentryPlugin: CreateSentryPlugin<MyAnalyticsInstance> = (
  pluginConfig
) => {
  if (pluginConfig.debug) {
    Log.enable('analytics:sentry')
  } else {
    Log.disable('analytics:sentry')
  }

  return {
    name: 'sentry',
    config: pluginConfig,

    identify: (bucket) => {
      const { payload, config, instance } = bucket
      logger.debug('identify', payload, config, instance)

      const email = payload.traits.primary_email_address || undefined
      const username = payload.traits.clerk_id || undefined

      config.setUser({
        id: payload.userId || payload.anonymousId,
        ...(email && { email }),
        ...(username && { username }),
      })
    },

    identifyAborted: (bucket) => {
      const { payload, config, instance } = bucket
      logger.debug('identifyAborted', payload, config, instance)

      config.setUser(null)
    },

    userIdChanged: (bucket) => {
      const { payload, config, instance } = bucket
      logger.debug('userIdChanged', payload, config, instance)
      config.setUser(bucket.config.formatUser(payload))
    },

    track: (bucket) => {
      const payload = bucket.payload

      const eventName = payload.event

      if (eventName === 'checkout_complete') {
        logger.debug(payload.properties.line_items)
      } else if (payload.event === 'onboarding_complete') {
        logger.debug(payload.properties.test)
      }
    },

    reset: (bucket) => {
      const { payload, config, instance } = bucket
      logger.debug('reset', payload, config, instance)

      config.setUser(null)
    },
  }
}
