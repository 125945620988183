import { Platform } from 'react-native'

const type = {
  /** Avenir Book */
  base: Platform.select({
    native: 'Avenir-Book' as const,
    default: `Avenir-Book, 'Avenir Book', sans-serif` as const,
  }),
  /** Sofia Pro Bold */
  bold: Platform.select({
    native: 'SofiaPro-Bold' as const,
    default:
      `sofia-pro, SofiaPro-Bold, 'Sofia Pro Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif` as const,
  }),
  /** Helvetica Neue Italic */
  emphasis: Platform.select({
    native: 'HelveticaNeue-Italic' as const,
    default: 'HelveticaNeue-Italic' as const,
  }),
  /** Source Sans Pro Semi-Bold */
  alternateBold: Platform.select({
    native: `SourceSansPro-SemiBold` as const,
    default:
      `SourceSansPro-SemiBold, 'Source Sans Pro', system-ui, sans-serif` as const,
  }),
  /** Source Sans Pro Bold */
  sourceBold: Platform.select({
    native: `SourceSansPro-Bold` as const,
    default:
      `SourceSansPro-Bold, 'Source Sans Pro', system-ui, sans-serif` as const,
  }),
  /** Source Sans Pro Regular */
  regular: Platform.select({
    native: `SourceSansPro-Regular` as const,
    default:
      `SourceSansPro-Regular, 'Source Sans Pro', system-ui, sans-serif` as const,
  }),
  /** Sofia Pro Regular */
  sofia: Platform.select({
    native: 'SofiaProRegular' as const,
    default:
      `sofia-pro, SofiaProRegular, 'Sofia Pro Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif` as const,
  }),
  /** Gilroy Regular*/
  gilroy: Platform.select({
    native: 'Gilroy-Regular' as const,
    default: 'Gilroy-Regular, Gilroy, system-ui, sans-serif' as const,
  }),
  /** Gilroy Medium */
  gilroyMedium: Platform.select({
    native: 'Gilroy-Medium' as const,
    default:
      'Gilroy-Medium, GilroyMedium, Gilroy, system-ui, sans-serif' as const,
  }),
  /** Gilroy Bold */
  gilroyBold: Platform.select({
    native: 'Gilroy-Bold' as const,
    default: 'Gilroy-Bold, GilroyBold, Gilroy, system-ui, sans-serif' as const,
  }),
} as const

const typeWithWeight = {
  /** Avenir Book */
  base: {
    fontFamily: type.base,
    fontWeight: 'normal' as const,
  },
  /** Sofia Pro Bold */
  bold: {
    fontFamily: type.bold,
    fontWeight: 'bold' as const,
  },
  /** Helvetica Neue Italic */
  emphasis: {
    fontFamily: type.emphasis,
    fontWeight: 'normal' as const,
  },
  /** Source Sans Pro Semi-Bold */
  alternateBold: {
    fontFamily: type.alternateBold,
    fontWeight: 'bold' as const,
  },
  /** Source Sans Pro Bold */
  sourceBold: {
    fontFamily: type.sourceBold,
    fontWeight: 'bold' as const,
  },
  /** Source Sans Pro Regular */
  regular: {
    fontFamily: type.regular,
    fontWeight: 'normal' as const,
  },
  /** Sofia Pro Regular */
  sofia: {
    fontFamily: type.sofia,
    fontWeight: 'normal' as const,
  },
  /** Gilroy Regular*/
  gilroy: {
    fontFamily: type.gilroy,
    fontWeight: 'normal' as const,
  },
  /** Gilroy Bold */
  gilroyBold: {
    fontFamily: type.gilroyBold,
    fontWeight: 'bold' as const,
  },
}

const size = {
  h1: 38,
  h2: 34,
  h3: 30,
  h4: 26,
  h5: 22,
  h6: 20,
  input: 18,
  regular: 16,
  regium: 15,
  medium: 13,
  small: 12,
  tiny: 9,
} as const

export const style = {
  h1: {
    fontFamily: type.base,
    fontSize: size.h1,
  },
  h2: {
    fontFamily: type.bold,
    fontWeight: 'bold' as const,
    fontSize: size.h2,
  },
  h3: {
    fontFamily: type.emphasis,
    fontSize: size.h3,
  },
  h4: {
    fontFamily: type.base,
    fontSize: size.h4,
  },
  h5: {
    fontFamily: type.base,
    fontSize: size.h5,
  },
  h6: {
    fontFamily: type.emphasis,
    fontSize: size.h6,
  },
  normal: {
    fontFamily: type.base,
    fontSize: size.regular,
  },
  description: {
    fontFamily: type.base,
    fontSize: size.medium,
  },
} as const

export const Fonts = {
  type,
  typeWithWeight,
  size,
  style,
} as const

export default Fonts
