type QueryKey = [number, string, string, number]

export function idFromHash(hash: string) {
  const queryKey = Buffer.from(hash, 'base64url').toString('utf8')
  const qk: QueryKey = JSON.parse(queryKey)
  const [version, schema, model, id] = qk
  return {
    version,
    schema,
    model,
    id,
  }
}

export function idToHash({
  id,
  model,
  schema = 'public',
  version = 1,
}: {
  id: number
  model: string
  schema?: string
  version?: number
}) {
  const queryKey = JSON.stringify([version, schema, model, id])
  return Buffer.from(queryKey).toString('base64url')
}
