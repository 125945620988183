import * as React from 'react'
import type { Router } from 'next/router'
import { env } from 'env'
import { clerkAppearanceOptions } from '@themes/ClerkThemeOptions'
import { ClerkProvider } from '~/universal/clerk'

export function AuthProvider(
  props: React.PropsWithChildren<{ router: Router }>
) {
  return (
    <ClerkProvider
      appearance={clerkAppearanceOptions}
      signInUrl={env.NEXT_PUBLIC_CLERK_SIGN_IN_URL}
      allowedRedirectOrigins={[
        'http://localhost:3020',
        'https://www.purposity.com',
        'https://admin-v3.purposity.com',
        'https://admin.staging.purposity.com',
      ]}
    >
      {props.children}
    </ClerkProvider>
  )
}
