import { trpc } from '@/utils/trpc'
import { useGeo } from '@features/geo/use-geo'
import { HASURA_NEXT_JWT } from '@purposity/auth'
import { analytics } from '@services/Analytics'
import { Log } from '@services/Logger'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as z from 'zod'
import { useReferral } from '~/features/referral/useReferral'
import { useClerk, useUser } from '~/universal/clerk'
import { captureException, setTag } from '~/universal/sentry'

type Props = object

const logger = Log.extend('clerk')

export function ClerkRefresh(props: Props) {
  useLogClerkChanges()
  useUpdater()
  useEnsureValidMetadata()

  return null
}

export const ErrorCode = z.nativeEnum({
  USER_NOT_FOUND: 'purposity/user-id-not-attached-to-clerk',
  NOT_RESOLVABLE: 'purposity/could-not-fix',
})

const IS_AUTH_V1_OR_V2 = false
/**
 * This hook triggers a serverside function that: validates a user's Clerk PublicMetadata; and if necessary and possible, updates Clerk with valid data.
 * It makes several attempts to fix the issue, and if it fails, it logs the error to Sentry.
 */
function useEnsureValidMetadata() {
  const { user, session } = useClerk()
  trpc.user.ensure_metadata.useQuery(null, {
    enabled: IS_AUTH_V1_OR_V2 && !!user?.id,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry(failureCount, error) {
      if (error.message === ErrorCode.enum.USER_NOT_FOUND) {
        return failureCount <= 10
      } else if (error.message === ErrorCode.enum.NOT_RESOLVABLE) {
        return failureCount <= 3
      }
      return failureCount <= 1
    },
    retryDelay: 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    trpc: { ssr: false },
    onError(error) {
      if (ErrorCode.safeParse(error.message).success) {
        setTag('error.type', 'invalid-clerk-metadata')
        setTag('error.code', error.message)
        if (error.message === ErrorCode.enum.USER_NOT_FOUND) {
          captureException(error, {
            level: 'warning',
            extra: {
              notes:
                'User has a Clerk account, but the clerk_id cannot be found in the database (users.slug)',
            },
          })
        } else if (error.message === ErrorCode.enum.NOT_RESOLVABLE) {
          captureException(error, {
            level: 'warning',
            extra: {
              notes:
                'User has a Clerk account and can be found in the database, but we are unable to build valid PublicMetadata from the database record',
            },
          })
        }
      }
    },
    async onSuccess() {
      return await Promise.all([
        user?.reload(),
        session?.getToken({ skipCache: true }),
        session?.getToken({ skipCache: true, template: HASURA_NEXT_JWT }),
      ])
    },
  })
}

function useLogClerkChanges() {
  const clerk = useClerk()
  useEffect(() => {
    return clerk.addListener((parameters) => {
      const session = parameters.session
      const log = Log.extend('clerk:session')
      log.debug('session', session)
    })
  }, [clerk])
}

function useUpdater() {
  const { isLoaded, isSignedIn, user } = useUser()
  const geo = useGeo()
  const { referral, setReferral } = useReferral()
  const { push } = useRouter()

  useEffect(() => {
    if (isLoaded) {
      if (isSignedIn) {
        logger.debug(`User is signed in: ${user.id}`)

        analytics.identify(user.publicMetadata.purposity_id, {
          primary_email_address: user.primaryEmailAddress?.emailAddress,
          primary_phone_number: user.primaryPhoneNumber?.phoneNumber,
          clerk_id: user.id,
          first_name: user.firstName || undefined,
          last_name: user.lastName || undefined,
          city: geo?.city,
          state: geo?.region,
          country: geo?.country,
        })
        if (referral) {
          const redirectUrl = referral.redirectUrl
          setReferral(null)
          push(redirectUrl)
        }
      } else {
        logger.debug(`User is not signed in`)
        analytics.reset()
      }
    }
  }, [
    geo?.city,
    geo?.country,
    geo?.region,
    isLoaded,
    isSignedIn,
    user?.firstName,
    user?.id,
    user?.lastName,
    user?.primaryEmailAddress?.emailAddress,
    user?.primaryPhoneNumber?.phoneNumber,
    user?.publicMetadata.purposity_id,
  ])
}
