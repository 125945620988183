export const formatCityState = (
  city?: string | null,
  state?: string | null,
  options?: {
    fullState?: boolean
    skipAP?: boolean
  }
) => {
  const fullState = options?.fullState ? getState(state) : state

  const AP_CITIES = new Map(
    Object.entries({
      atlanta: 'Atlanta',
      baltimore: 'Baltimore',
      boston: 'Boston',
      chicago: 'Chicago',
      cincinnati: 'Cincinnati',
      cleveland: 'Cleveland',
      dallas: 'Dallas',
      denver: 'Denver',
      detroit: 'Detroit',
      honolulu: 'Honolulu',
      houston: 'Houston',
      indianapolis: 'Indianapolis',
      'las vegas': 'Las Vegas',
      'los angeles': 'L.A.',
      miami: 'Miami',
      milwaukee: 'Milwaukee',
      minneapolis: 'Minneapolis',
      'new orleans': 'New Orleans',
      'new york': 'NYC',
      'oklahoma city': 'Oklahoma City',
      philadelphia: 'Philadelphia',
      phoenix: 'Phoenix',
      pittsburgh: 'Pittsburgh',
      'st. louis': 'St. Louis',
      'st louis': 'St. Louis',
      'saint louis': 'St. Louis',
      'salt lake city': 'Salt Lake City',
      'salt lake': 'Salt Lake City',
      'san antonio': 'San Antonio',
      'san diego': 'San Diego',
      'san francisco': 'San Francisco',
      seattle: 'Seattle',
      washington: 'D.C.',
    })
  )

  if (!options?.skipAP && AP_CITIES.has(`${city?.trim().toLowerCase()}`))
    return AP_CITIES.get(`${city?.trim().toLowerCase()}`)
  if (city && fullState) {
    return `${city.trim()}, ${fullState}`
  } else if (city) {
    return city
  } else if (fullState) {
    return getState(state)
  }
  return null
}

enum STATES {
  al = 'Alabama',
  ak = 'Alaska',
  az = 'Arizona',
  ar = 'Arkansas',
  ca = 'California',
  co = 'Colorado',
  ct = 'Connecticut',
  de = 'Delaware',
  dc = 'District of Columbia',
  fl = 'Florida',
  ga = 'Georgia',
  hi = 'Hawaii',
  id = 'Idaho',
  il = 'Illinois',
  in = 'Indiana',
  ia = 'Iowa',
  ks = 'Kansas',
  ky = 'Kentucky',
  la = 'Louisiana',
  me = 'Maine',
  md = 'Maryland',
  ma = 'Massachusetts',
  mi = 'Michigan',
  mn = 'Minnesota',
  ms = 'Mississippi',
  mo = 'Missouri',
  mt = 'Montana',
  ne = 'Nebraska',
  nv = 'Nevada',
  nh = 'New Hampshire',
  nj = 'New Jersey',
  nm = 'New Mexico',
  ny = 'New York',
  nc = 'North Carolina',
  nd = 'North Dakota',
  oh = 'Ohio',
  ok = 'Oklahoma',
  or = 'Oregon',
  pa = 'Pennsylvania',
  ri = 'Rhode Island',
  sc = 'South Carolina',
  sd = 'South Dakota',
  tn = 'Tennessee',
  tx = 'Texas',
  ut = 'Utah',
  vt = 'Vermont',
  va = 'Virginia',
  wa = 'Washington',
  wv = 'West Virginia',
  wi = 'Wisconsin',
  wy = 'Wyoming',
}

const getState = (state: STATES | string | null | undefined) => {
  if (typeof state === 'string') {
    if (state.length > 3) return state
    const std = state.replace('.', '').toLowerCase() as keyof typeof STATES
    console.log('getState std', std)
    if (std in STATES) {
      return STATES[std]
    }
  }
  return state
}
