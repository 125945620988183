import * as z from 'zod'
import type { LineItem } from '@purposity/sdk'
import { ValidId } from '@purposity/utils'

const teamJoin = z.object({
  id: ValidId,
})

const teamLeave = z.object({
  id: ValidId,
})

const teamInvite = z.object({
  id: ValidId,
})

const orgFollow = z.object({
  id: ValidId,
  orgName: z.string(),
})

const orgUnfollow = z.object({
  id: ValidId,
})

/** derived from CheckoutSession
 * @see packages/backend-sdk/src/features/transactions/types.ts
 * */
const transactionSchema = z.object({
  line_items: z.custom<LineItem>().array(),
  summary: z.object({
    count: z.number().int(),
    sum: z.number(),
  }),
})

const checkoutCreate = transactionSchema.partial()

const checkoutComplete = transactionSchema.partial()

const checkoutCancel = z.object({
  checkout_session_id: z.string(),
})

const goalSet = z.object({
  //
})

const userSignup = z.object({
  //
})

const userLogin = z.object({
  //
})

const userLogout = z.object({
  //
})

const needShare = z.object({
  //
})

const onboardingStart = z.object({
  //
})

const onboardingComplete = z.object({
  test: z.literal('me').optional(),
})

export const EventMap = z.object({
  team_join: teamJoin,
  team_leave: teamLeave,
  team_invite: teamInvite,
  org_follow: orgFollow,
  org_unfollow: orgUnfollow,
  goal_set: goalSet,
  user_signup: userSignup,
  user_login: userLogin,
  user_logout: userLogout,
  checkout_create: checkoutCreate,
  checkout_complete: checkoutComplete,
  checkout_cancel: checkoutCancel,
  need_share: needShare,
  onboarding_start: onboardingStart,
  onboarding_complete: onboardingComplete,
})
export type EventMap = z.infer<typeof EventMap>

// export interface EventMap {
//   team_join: z.infer<typeof teamJoin>
//   team_leave: z.infer<typeof teamLeave>
//   team_invite: z.infer<typeof teamInvite>
//   org_follow: z.infer<typeof orgFollow>
//   org_unfollow: z.infer<typeof orgUnfollow>
//   goal_set: z.infer<typeof goalSet>
//   user_signup: z.infer<typeof userSignup>
//   user_login: z.infer<typeof userLogin>
//   user_logout: z.infer<typeof userLogout>
//   checkout_create: z.infer<typeof checkoutCreate>
//   checkout_complete: z.infer<typeof checkoutComplete>
//   checkout_cancel: z.infer<typeof checkoutCancel>
//   need_share: z.infer<typeof needShare>
//   onboarding_start: z.infer<typeof onboardingStart>
//   onboarding_complete: z.infer<typeof onboardingComplete>
// }

export type Options = {
  eventId?: string
}
