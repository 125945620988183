export interface CloudflareImageResizingOptions {
  width: number
  height: number
  dpr: number
  fit: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad'
  gravity: 'auto' | 'left' | 'right' | 'top' | 'bottom' | `${number}x${number}`
  quality: number
  format: 'auto'
  anim: boolean
  sharpen: number
  blur: number
  onerror: 'redirect'
  metadata: 'keep' | 'copyright' | 'none'
}

export function createCloudflareImageUrl(
  /** original src of the image; full href, or path if hosted on cdn */
  src: string,
  cdnBase: URL,
  options: Partial<CloudflareImageResizingOptions> = {}
): string {
  return new URL(
    [`cdn-cgi`, `image`, serializeOptions(options), normalizeSrc(src)].join(
      `/`
    ),
    cdnBase
  ).href
}

function serializeOptions(options: Record<string, string | number | boolean>) {
  return Object.entries(options)
    .map(([key, value]) => [key, encodeURIComponent(value)].join('='))
    .join(',')
}

function normalizeSrc(src: string) {
  return src[0] === '/' ? src.slice(1) : src
}
