import { ApiClientConfig, SdkFunctionWrapper, getBaseSdk } from './getBaseSdk'

export type BucketConfig = {
  gql: ApiClientConfig & {
    withWrapper?: SdkFunctionWrapper
  }
  // stripe: Stripe
}

export function createBucket(bucketConfig: BucketConfig) {
  return {
    gql: getBaseSdk(bucketConfig.gql, bucketConfig.gql.withWrapper),
    // stripe: bucketConfig.stripe,
  } as const
}

export function withBucket<TCb>(cb: (bucket: Bucket) => TCb) {
  return (bucket: Bucket) => cb(bucket)
}
export type Bucket = ReturnType<typeof createBucket>
