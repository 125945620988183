import { createEnv } from '@t3-oss/env-nextjs'
import * as z from 'zod'
import { looseHrefSchema } from '@purposity/utils'

function requireInProduction(/** @type {*} */ v) {
  const isStringMin1 = typeof v === 'string' && v.length > 0
  if (process.env.VERCEL_ENV === 'production' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return isStringMin1
  } else {
    return true
  }
}

export const env = createEnv({
  server: {
    ADMIN_API_SECRET: z.string().min(1),
    ADMIN_SITE_API_TOKEN: z.string().min(1),
    ADMIN_SITE_ORIGIN: looseHrefSchema,
    API_ORIGIN: looseHrefSchema,
    CLERK_JWT_KEY: z.string().min(1),
    CLERK_SECRET_KEY: z.string().refine((s) => s.startsWith('sk_')),
    CLERK_PUBLISHABLE_KEY: z.string().refine((s) => s.startsWith('pk_')),
    CONNECT_SITE_ORIGIN: looseHrefSchema,
    DEBUG: z.coerce.boolean().optional().default(false),
    DOPPLER_CONFIG: z.string().min(1),
    DOPPLER_ENVIRONMENT: z.enum(['dev', 'preview', 'prod']),
    DOPPLER_PROJECT: z.string().min(1),
    EDGE_CONFIG: z.string().min(1),
    GA_MEASUREMENT_ID_ALTERNATE: z.string().min(1),
    GA_MEASUREMENT_ID: z.string().min(1).optional().refine(requireInProduction),
    HASURA_WEBHOOK_SECRET: z.string().min(1),
    LEGACY_API_PROXY_ORIGIN: looseHrefSchema.optional(),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    SECRET_KEY: z.string().min(1),
    SENTRY_AUTH_TOKEN: z.string().optional().refine(requireInProduction),
    STRIPE_SECRET_KEY: z.string().min(1),
    TIP_PRODUCT: z.string().min(1),
    UPSTASH_REST_API_DOMAIN: looseHrefSchema,
    UPSTASH_REST_API_TOKEN_READONLY: z.string().min(1),
    UPSTASH_REST_API_TOKEN: z.string().min(1),
    VERCEL_ENV: z.enum(['development', 'preview', 'production']).optional(),
    PTREE_EDGE_CONFIG: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_NODE_ENV: z.enum(['development', 'test', 'production']),
    NEXT_PUBLIC_API_ORIGIN: looseHrefSchema,
    NEXT_PUBLIC_CDN_ORIGIN: looseHrefSchema,
    NEXT_PUBLIC_CLERK_AUTH_DOMAIN: z.string().min(1),
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string().min(1),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().refine((v) => v.startsWith('pk_')),
    NEXT_PUBLIC_FB_PIXEL_ID: z.string().catch('FB-'),
    NEXT_PUBLIC_GA_MEASUREMENT_ID: z.string().min(1),
    NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE: z.string().min(1),
    NEXT_PUBLIC_LOGFLARE_API_KEY: z.string().min(1).catch('').refine(requireInProduction),
    NEXT_PUBLIC_LOGFLARE_SOURCE_KEY: z.string().min(1).catch('').refine(requireInProduction),
    NEXT_PUBLIC_SANITY_PROJECT_ID: z.string(),
    NEXT_PUBLIC_SANITY_DATASET: z.string(),
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional().refine(requireInProduction),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'preview', 'production']).optional(),
  },
  runtimeEnv: {
    ADMIN_API_SECRET: process.env.ADMIN_API_SECRET,
    ADMIN_SITE_API_TOKEN: process.env.ADMIN_SITE_API_TOKEN,
    ADMIN_SITE_ORIGIN: process.env.ADMIN_SITE_ORIGIN,
    API_ORIGIN: process.env.API_ORIGIN,
    CLERK_JWT_KEY: process.env.CLERK_JWT_KEY,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    CLERK_PUBLISHABLE_KEY: process.env.CLERK_PUBLISHABLE_KEY,
    CONNECT_SITE_ORIGIN: process.env.CONNECT_SITE_ORIGIN,
    DEBUG: process.env.DEBUG,
    DOPPLER_CONFIG: process.env.DOPPLER_CONFIG,
    DOPPLER_ENVIRONMENT: process.env.DOPPLER_ENVIRONMENT,
    DOPPLER_PROJECT: process.env.DOPPLER_PROJECT,
    EDGE_CONFIG: process.env.EDGE_CONFIG,
    GA_MEASUREMENT_ID_ALTERNATE: process.env.GA_MEASUREMENT_ID_ALTERNATE,
    GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID,
    HASURA_WEBHOOK_SECRET: process.env.HASURA_WEBHOOK_SECRET,
    LEGACY_API_PROXY_ORIGIN: process.env.LEGACY_API_PROXY_ORIGIN,
    NEXT_PUBLIC_API_ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN,
    NEXT_PUBLIC_CDN_ORIGIN: process.env.NEXT_PUBLIC_CDN_ORIGIN,
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
    NEXT_PUBLIC_CLERK_AUTH_DOMAIN: process.env.NEXT_PUBLIC_CLERK_AUTH_DOMAIN,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_FB_PIXEL_ID: process.env.NEXT_PUBLIC_FB_PIXEL_ID,
    NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_LOGFLARE_API_KEY: process.env.NEXT_PUBLIC_LOGFLARE_API_KEY,
    NEXT_PUBLIC_LOGFLARE_SOURCE_KEY: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_KEY,
    NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
    NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    NODE_ENV: process.env.NODE_ENV,
    PTREE_EDGE_CONFIG: process.env.PTREE_EDGE_CONFIG,
    SECRET_KEY: process.env.SECRET_KEY,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    TIP_PRODUCT: process.env.TIP_PRODUCT,
    UPSTASH_REST_API_DOMAIN: process.env.UPSTASH_REST_API_DOMAIN,
    UPSTASH_REST_API_TOKEN_READONLY: process.env.UPSTASH_REST_API_TOKEN_READONLY,
    UPSTASH_REST_API_TOKEN: process.env.UPSTASH_REST_API_TOKEN,
    VERCEL_ENV: process.env.VERCEL_ENV,
  },
  onValidationError(n) {
    throw (console.error('\u274C Invalid environment variables:', n.flatten().fieldErrors), new Error(`Invalid environment variables: ${Object.keys(n.flatten().fieldErrors).join(', ')}`))
  },
  skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',
})
