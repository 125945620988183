import * as React from 'react'
import { Toaster } from 'react-hot-toast'
import Fonts from '@themes/Fonts'

const config = {
  className: '',
  duration: 5000,
  style: {
    fontFamily: Fonts.type.sourceBold,
    background: '#363636',
    color: '#fff',
  },
  // Default options for specific types
  success: {
    duration: 3000,
    theme: {
      primary: 'green',
      secondary: 'black',
    },
  },
}

export default function ToastsPortal() {
  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={config}
    />
  )
}
