import * as z from 'zod'
import {
  createClient,
  createSuperAdminClient,
  SuperAdminConfig,
  type Config as UserConfig,
} from '@purposity/api-client'
import * as primary from '../__generated/primary/sdk'
import * as relay from '../__generated/relay/sdk'
import type { GraphQLClient } from 'graphql-request'

export type SdkFunctionWrapper = primary.SdkFunctionWrapper

export const SdkMode = z.enum(['SUPER_ADMIN', 'USER'])
export type SdkMode = z.infer<typeof SdkMode>

export type ApiClientConfig = UserConfig | SuperAdminConfig

export type BaseSdk = primary.Sdk &
  relay.Sdk & {
    __primaryClient: GraphQLClient
    __relayClient: GraphQLClient
    __headers: Record<string, string>
    mode: SdkMode
  }

export function getBaseSdk(
  config: ApiClientConfig,
  withWrapper: SdkFunctionWrapper | undefined
): BaseSdk {
  // Super Admin Client
  if ('secret' in config) {
    const [__primaryClient, __headers] = createSuperAdminClient(config)
    const primarySdk = primary.getSdk(__primaryClient, withWrapper)

    const [__relayClient] = createSuperAdminClient({
      ...config,
      endpoint: '/v1beta1/relay',
    })
    const relaySdk = relay.getSdk(__relayClient, withWrapper)

    return {
      ...primarySdk,
      ...relaySdk,
      __primaryClient,
      __relayClient,
      __headers,
      mode: SdkMode.enum.SUPER_ADMIN,
    }
  } else {
    const [__primaryClient, __headers] = createClient(config)
    const primarySdk = primary.getSdk(__primaryClient, withWrapper)
    const [__relayClient] = createClient({
      ...config,
      endpoint: '/v1beta1/relay',
    })
    const relaySdk = relay.getSdk(__relayClient, withWrapper)

    return {
      ...primarySdk,
      ...relaySdk,
      __primaryClient,
      __relayClient,
      __headers,
      mode: SdkMode.enum.USER,
    }
  }
}

type Callback<TFn> = (sdk: BaseSdk) => TFn
export function withSdk<TCb>(cb: Callback<TCb>) {
  return (sdk: BaseSdk) => cb(sdk)
}
