import * as z from 'zod'
import { domainNameSchema } from './domain-name'

const RE = /pk_(test|live)_([0-9A-Za-z]+)/

export function validDomainRefinement(val: string, ctx?: z.RefinementCtx) {
  const encDomainArr = val.split('_')
  const encDomain = encDomainArr[2]

  if (encDomain == null) {
    ctx?.addIssue({
      code: 'custom',
      message: 'Third param is not a string',
      fatal: true,
      path: [2],
    })
    return z.NEVER
  }

  let decDomain: string
  try {
    decDomain = atob(encDomain).replace('$', '')
  } catch {
    ctx?.addIssue({
      code: 'custom',
      path: [2],
      message: 'Could not decode base64 string',
      fatal: true,
    })
    return z.NEVER
  }

  if (!decDomain) return z.NEVER

  const parsedDomain = domainNameSchema.safeParse(decDomain)
  if (!parsedDomain.success) {
    ctx?.addIssue({
      code: 'custom',
      path: [2],
      message: parsedDomain.error.message,
      params: {},
      fatal: true,
    })
    return z.NEVER
  }

  return parsedDomain.data
}

/**
 * @description valid Clerk Publishable Key
 */
export const clerkPkSchema = z
  .string()
  .regex(RE, 'Did not match RegEx')
  .superRefine(validDomainRefinement)

/**
 * @description parses a Clerk Publishable Key and returns the encoded domain as a URL
 */
export const clerkPkDomainSchema = clerkPkSchema
  .transform(validDomainRefinement)
  .transform((v) => new URL(`https://${v}`))
