import * as z from 'zod'
import env from '../env/server'
import { ClerkId } from '../lib/clerk/_shared'

const Input = z.object({
  userId: ClerkId,
  tokenName: z.enum([
    '__session',
    'basic',
    'hasura',
    'hasura-next',
    'legacy-long-lived-token',
  ]),
})

const Options = z.object({
  apiKey: z
    .string()
    .startsWith('sk_')
    .catch(() => env.CLERK_SECRET_KEY ?? ''),
})

const ApiResponse = z.object({
  object: z.literal('token'),
  jwt: z.string(),
})
type ApiResponse = z.infer<typeof ApiResponse>

const Output = z.string()
type Output = z.infer<typeof Output>

export const createClerkJwt = z
  .function()
  .args(Input, Options)
  .returns(z.promise(Output))
  .implement(async (input, options) => {
    const getToken = await fetch(
      new URL('/v1/tokens', 'https://api.clerk.dev'),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${options.apiKey}`,
        },
        body: JSON.stringify({
          name: input.tokenName,
          user_id: input.userId,
        }),
      }
    )
    const json = await getToken.json()
    const response = ApiResponse.parse(json)
    return response.jwt
  })
