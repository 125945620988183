import type * as z from 'zod'

export const envIs = <T>(envs: T[]) => {
  /** @param currentEnv inline `process.env.NODE_ENV` or ...`VERCEL_ENV` or ...`NEXT_PUBLIC_VERCEL_ENV`, etc. */
  return (currentEnv: any) => () => envs.includes(currentEnv)
}

export function requiredIf<TInput>(
  predicate: () => boolean,
  fallbackValue: TInput
) {
  return ({ error, input }: { error: z.ZodError<any>; input: unknown }) => {
    const isRequired = predicate()
    const isNullishError = error.issues.some(
      (issue) =>
        issue.code === 'invalid_type' &&
        (issue.received === 'undefined' || issue.received === 'null')
    )

    if (isRequired && isNullishError) throw error

    return fallbackValue
  }
}
