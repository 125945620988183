import * as z from 'zod'

/**
 * @see https://regexr.com/3e8n2
 */
const RE =
  /^((?:([a-z0-9]\.|[a-z0-9][a-z0-9\-]{0,61}[a-z0-9])\.)+)([a-z0-9]{2,63}|(?:[a-z0-9][a-z0-9\-]{0,61}[a-z0-9]))\.?$/

export const domainNameSchema = z
  .string()
  .regex(RE, 'Must match domain name')
  .describe('domain name')
