import * as z from 'zod'

export const HeadersInit: z.ZodType<RequestInit['headers']> = z
  .union([
    z.tuple([z.string(), z.string()]).array(),
    z.record(z.string(), z.string()),
  ])
  .optional()

export const SignalInit: z.ZodType<RequestInit['signal']> = z
  .instanceof(AbortSignal)
  .nullish()
