import * as z from 'zod'

const Params = z.object({
  id: z.coerce.number().transform((v) => `${v}`),
  title: z.string(),
  description: z.string(),
  price_including_tip: z.string(),
  city: z.string(),
  state: z.string(),
  organization_name: z.string(),
  organization_profile_image_path: z.string().url(),
  token: z.string(),
})

export const getOpenGraphNeedUrlModern = z
  .function()
  .args(Params)
  .returns(z.string().url())
  .implement((params) => {
    const base = new URL('https://www.purposity.com/api/og')

    const searchParams = new URLSearchParams(
      Object.entries(params).map(([key, value]) => [key, value])
    )
    searchParams.set(
      'organization_profile_image_path',
      `https://cdn.purposity.com/cdn-cgi/image/height=96,metadata=none,fit=cover,format=auto,width=96,quality=100/${params.organization_profile_image_path}`
    )

    return new URL(`?${searchParams}`, base).href
  })
