import * as React from 'react'
import { appConfig } from 'config'
// @dirty react-favicon
import ReactFavicon from 'react-favicon'
import { envColors } from '~/Themes/EnvColors'

export function Favicon() {
  return appConfig.purposityEnv === 'production' ? (
    <ReactFavicon
      url="/favicon.png"
      iconSize={64}
      animated={false}
      keepIconLink={() => true}
    />
  ) : (
    <ReactFavicon
      url="/favicon.png"
      iconSize={64}
      animated={false}
      keepIconLink={() => true}
      alertCount={appConfig.purposityEnv.slice(0, 1).toUpperCase()}
      alertFillColor={envColors.get(appConfig.purposityEnv)?.fillColor}
      alertTextColor={envColors.get(appConfig.purposityEnv)?.textColor}
    />
  )
}
