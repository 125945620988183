import * as z from 'zod'
import { IdSchema, getRandomId } from '@purposity/utils'
import { type _CreateCheckoutSessionMutationVariables } from '../../__generated/primary/types'
import { withBucket } from '../../lib/createBucket'
import { Properties } from '../../utils'
import { HeadersInit, SignalInit } from '../_common'
import { CheckoutSession } from '../checkout/schemas'

type Params = _CreateCheckoutSessionMutationVariables
const Params = z.object<Properties<Params>>({
  need_ids: IdSchema.array(),
})

export default withBucket((bucket) => {
  return z
    .function()
    .args(Params, HeadersInit, SignalInit)
    .implement(async (params, headersInit, signalInit) => {
      const eventId = getRandomId()

      const headers = new Headers(headersInit)
      headers.set('x-purposity-event-id', eventId)

      const result = await bucket.gql._createCheckoutSession(params, headers)

      return z
        .object({
          session: CheckoutSession,
          metadata: z.object({ eventId: z.string() }),
        })
        .parse({ session: result.session, metadata: { eventId } })
    })
})
