import { Level } from '../shared'
import type { TransportFunction } from '../types'

const getSub = (key: string) => {
  const createLog = (level: 'debug' | 'info' | 'warn' | 'error' | 'log') => {
    return function (...args: any[]) {
      args.unshift(`[${key}]`)
      const logger = console[level].bind(console)
      logger(...args)
    }
  }

  return {
    debug: createLog('debug'),
    info: createLog('info'),
    inspect: createLog('info'),
    warn: createLog('warn'),
    error: createLog('error'),
    log: createLog('log'),
  }
}

const consoleTransport: TransportFunction = (props) => {
  const level = Level.parse(props.level.text)

  const mainLogger = props.extension ? getSub(props.extension) : console

  const logFns: Record<Level, (...args: any[]) => void> = {
    debug: mainLogger.debug,
    info: mainLogger.info,
    inspect: mainLogger.info,
    warn: mainLogger.warn,
    error: mainLogger.error,
  }
  const logFn = logFns[level]
  logFn(...props.rawMsg)
}

export { consoleTransport }
