import type { SdkFunctionWrapper } from '@purposity/sdk'

export const sdkFnWrapper: SdkFunctionWrapper = async (
  action,
  operationName,
  operationType
) => {
  const graphqlHeaders = {
    'x-graphql-operation-name': operationName,
    ...(operationType && {
      'x-graphql-operation-type': operationType,
    }),
  }

  const result = await action(graphqlHeaders)
  return result
}
