import runtime from '../../runtime'

export interface Signer<TData extends any = any> {
  sign: (data: TData) => Promise<string>
  verify: (data: TData, token: string) => Promise<boolean>
}

export async function createSigner<TData = any>(
  secret: string
): Promise<Signer<TData>> {
  const key = runtime.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(secret),
    { name: 'HMAC', hash: { name: 'SHA-256' } },
    false,
    ['sign']
  )

  return {
    sign: createSignature,
    verify: verifySignature,
  }
  async function createSignature(data: TData) {
    const hmac = toHex(
      await runtime.crypto.subtle.sign(
        'HMAC',
        await key,
        new TextEncoder().encode(JSON.stringify({ ...data }))
      )
    )

    return hmac
  }
  async function verifySignature(data: TData, token: string) {
    const verifyToken = await createSignature(data)

    if (token !== verifyToken) {
      return false
    }

    return true
  }

  function toHex(arrayBuffer: ArrayBuffer) {
    return Array.prototype.map
      .call(new Uint8Array(arrayBuffer), (n) => n.toString(16).padStart(2, '0'))
      .join('')
  }
}
