import { createRootLogger } from 'logger'
import type { PurposityEnv } from 'purposity-env'

const logger = createRootLogger(undefined, {
  severity: 'info',
}).extend('api-client:headers')

/** The name of the app
 * @example 'webhooks'
 */
type AppName = string

/** The type of platform the code is being executed on */
type Platform = 'server' | 'web' | 'ios' | 'android'

type VercelEnv = 'development' | 'preview' | 'production'

/**
 * What is the name of the function that is acting as the client for the GraphQL API?
 * @example 'light-backend-api' | 'backend-api' | 'admin-api' | 'user-dynamic-api'
 */
type GraphQLClientName = string

type AppConfig = {
  app: AppName
  env: PurposityEnv
  platform: Platform
  'graphql-client': GraphQLClientName
}

function getApp(appInit?: string): AppName {
  const appName = appInit
  if (appName) {
    return appName
  } else {
    logger.warn('No app name provided, using default')
    return 'unknown'
  }
}

function getPlatform() {
  const isReactNative =
    typeof navigator !== 'undefined' &&
    typeof navigator.product === 'string' &&
    navigator.product.toLowerCase() === 'reactnative'

  if (isReactNative) {
    return 'ios'
  }

  const isBrowser =
    typeof window !== 'undefined' && typeof window.document !== 'undefined'

  return isBrowser ? 'web' : 'server'
}

function getGraphQLClient(graphQlClientInit?: string) {
  if (!graphQlClientInit) console.warn('No GraphQLClientName provided')
  return graphQlClientInit || 'unknown'
}

const addAppConfigHeaders = (
  appConfig: AppConfig,
  headersInit?: HeadersInit
) => {
  const headers = new Headers(headersInit)
  Object.entries(appConfig).forEach(([key, value]) =>
    headers.set(`x-purposity-${key}`, value)
  )
  return headers
}

/** Hasura Client Name */
type HasuraClientName = `${Platform}:${AppName}_${PurposityEnv}`
const formatHasuraClientName: (appConfig: AppConfig) => HasuraClientName = (
  appConfig
) => `${appConfig.platform}:${appConfig.app}_${appConfig.env}`

function addHasuraClientHeader(
  appConfig: AppConfig,
  headersInit?: HeadersInit
) {
  const headers = new Headers(headersInit)
  headers.set('hasura-client-name', formatHasuraClientName(appConfig))
  return headers
}

/**
 * @default VERCEL_ENV || NEXT_PUBLIC_VERCEL_ENV
 */
function addVercelEnvHeader(
  headersInit?: HeadersInit,
  overrides?: {
    env?: VercelEnv
    purposityEnv?: PurposityEnv
  }
) {
  const headers = new Headers(headersInit)

  const X_VERCEL_ENV_KEY = 'x-vercel-env'
  const __env = overrides?.env

  if (__env) {
    headers.set(X_VERCEL_ENV_KEY, __env)
  } else {
    headers.set(X_VERCEL_ENV_KEY, '(unknown)')
    headers.set(`${X_VERCEL_ENV_KEY}-source`, 'fallback')
  }

  return headers
}

/** MAIN */

export function getHeaders(
  options: {
    clientName: GraphQLClientName

    /**
     * @default DOPPLER_PROJECT
     */
    appInit?: AppName

    purposityEnv: PurposityEnv

    /** Include the Hasura specific headers
     * @default true
     */
    withHasuraHeaders?: boolean
  },
  headersInit?: HeadersInit
) {
  const appConfig: AppConfig = {
    app: getApp(options?.appInit),
    env: options.purposityEnv,
    platform: getPlatform(),
    'graphql-client': getGraphQLClient(options.clientName),
  }

  let mainHeaders = new Headers(headersInit)
  mainHeaders = addAppConfigHeaders(appConfig, mainHeaders)

  if (options.withHasuraHeaders ?? true) {
    mainHeaders = addHasuraClientHeader(appConfig, mainHeaders)
  }

  mainHeaders = addVercelEnvHeader(mainHeaders, { purposityEnv: appConfig.env })

  const result: Record<string, string> = {}
  new Headers(mainHeaders).forEach((value: string, key: string) => {
    result[key] = value
  })
  return result
}
