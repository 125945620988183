import { useState, useEffect, useDebugValue } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { appConfig } from 'config'
// import Cookies from 'cookies-ts'
import { createParam } from 'solito'
import { Log } from '@services/Logger'
import { KEY } from '../constants'

const DEBUG_LOGGING = false
const logger = Log.extend('use-enabled')

// const cookies = new Cookies()

const { useParams } = createParam()

export function useEnabled(key: string = KEY, defaultValue?: boolean) {
  const { params } = useParams()

  const [enabled, setEnabled] = useState(defaultValue ?? false)
  useEffect(() => {
    const checkEnabled = async () => {
      // const fromCookie = cookies.get(key)
      const fromSearch = params.key
      const fromLocalStorage = await AsyncStorage.getItem(key)

      const explicitlyDisabled =
        // fromCookie === 'false' ||
        fromSearch === 'false' || fromLocalStorage === 'false'

      const explicitlyEnabled =
        // fromCookie === 'true' ||
        fromSearch === 'true' || fromLocalStorage === 'true'

      DEBUG_LOGGING &&
        logger.info(key, {
          // fromCookie,
          fromSearch,
          fromLocalStorage,
          explicitlyDisabled,
          explicitlyEnabled,
        })

      if (
        !explicitlyDisabled &&
        (appConfig.purposityEnv === 'development' || explicitlyEnabled)
      ) {
        setEnabled(true)
      }
    }

    checkEnabled()
  }, [key, params.key])
  useDebugValue({
    enabled,
    key,
  })
  return enabled
}
