import * as z from 'zod'

export const Role = z.enum([
  'public',
  'user',
  'organizer',
  'org_admin',
  'admin',
  'user_public',
  'super_admin',
])

export type Role = z.infer<typeof Role>
