import { Platform } from 'react-native'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { applicationId } from 'expo-application'
import { DeviceType, deviceType } from 'expo-device'
import {
  getDevicePushTokenAsync,
  getExpoPushTokenAsync,
} from 'expo-notifications'
import * as z from 'zod'

export const deviceInfoQK = createQueryKeys('device_info', {
  all: null,
  type: {
    queryKey: null,
    queryFn: getDeviceType,
  },
  local_id: {
    queryKey: null,
    queryFn: getDeviceLocalId,
  },
  bundle_id: {
    queryKey: null,
    queryFn: getBundleId,
  },
  updates: {
    queryKey: null,
  },
  token: (tokenType: 'expo' | 'apns' | 'fcm' | 'onesignal') => ({
    queryKey: [tokenType],
    queryFn:
      tokenType === 'onesignal'
        ? getOneSignalToken
        : tokenType === 'expo'
        ? getExpoPushTokenAsync
        : getDevicePushTokenAsync,
  }),
  geo: (id: string) => ({
    queryKey: [id],
  }),
  custom: (key: string) => ({
    queryKey: [key] as const,
  }),
})
function getBundleId() {
  return z
    .enum([
      'com.purposity.app',
      'com.purposity.app.dev',
      'com.purposity.app.staging',
      'com.purposity.app.preview',
      'com.purposity.app.canary',
      'host.exp.Exponent',
    ])
    .parse(applicationId)
}

async function getDeviceLocalId() {
  // TODO: revise to use react-native-device-info
  // if (force) {
  //   context
  // handle restored from backup on new device
  // await DeviceInfo.syncUniqueId()
  // }
  const idfv = await import('expo-application').then((mod) =>
    mod.getIosIdForVendorAsync()
  )

  if (idfv === null) {
    throw new Error('Failed to get idfv')
  }

  return idfv
}
async function getDeviceType() {
  return [Platform.OS, deviceType ? DeviceType[deviceType] : 'unknown']
    .join(':')
    .toLowerCase()
}

async function getOneSignalToken() {
  return AsyncStorage.getItem('purposity:onesignal')
}
