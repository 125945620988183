export const HASURA_JWT = 'hasura' as const
export const HASURA_NEXT_JWT = 'hasura-next' as const
export const BASIC_JWT = 'basic' as const
export const REDIRECT_URL_KEY = 'redirect_url' as const
export const LOGIN_PATH = '/login' as const
export const SIGNUP_PATH = '/signup' as const

export const HOME_PATH = '/home' as const

export const DEFAULT_LOGIN_REDIRECT_URL =
  `${HOME_PATH}/?src=default-login` as const
export const DEFAULT_SIGNUP_REDIRECT_URL =
  `${HOME_PATH}/?src=default-signup` as const

export const NEW_USER_PATH = '/new-user' as const

export const makeLoginRedirect = (destination: URL) => {
  const urlCopy = new URL(destination)
  urlCopy.pathname = LOGIN_PATH
  urlCopy.searchParams.set(REDIRECT_URL_KEY, destination.pathname)
  return urlCopy
}

export const makeSignupRedirect = (destination: URL) => {
  const urlCopy = new URL(destination)
  urlCopy.pathname = SIGNUP_PATH
  urlCopy.searchParams.set(REDIRECT_URL_KEY, destination.pathname)
  return urlCopy
}
