import * as z from 'zod'
import { invariant } from '@purposity/utils'
import { type _ExpireCheckoutSessionMutationVariables } from '../../__generated/primary/types'
import { withBucket } from '../../lib/createBucket'
import { Properties } from '../../utils'
import { HeadersInit, SignalInit } from '../_common'
import { CheckoutSessionId } from '../checkout/schemas'

type Params = _ExpireCheckoutSessionMutationVariables
const Params = z.object<Properties<Params>>({
  checkout_session_id: CheckoutSessionId,
})

export default withBucket((bucket) => {
  return z
    .function()
    .args(Params, HeadersInit, SignalInit)
    .implement(async (params, headersInit, signalInit) => {
      const result = await bucket.gql._expireCheckoutSession(
        params,
        headersInit
      )
      invariant(result.session, 'No session returned')
      return result.session
    })
})
