import runSignupTasks_ from './experimental/features/auth/run-signup-tasks'
import getDevice_ from './experimental/features/device/get-device'
import initDevice_ from './experimental/features/device/init-device'
import getIpLocation_ from './experimental/features/geo/get-ip-location'
import checkoutSession_ from './features/checkout-session'
import universal_ from './features/universal-checkout'
import { BucketConfig, createBucket } from './lib/createBucket'
import type { BaseSdk } from 'lib/getBaseSdk'

type BaseSdkKeys = keyof BaseSdk

type StreamlinedBaseSdk = Exclude<
  BaseSdkKeys,
  | '_createCheckoutSession'
  | '_expireCheckoutSession'
  | '_cancelUniversalCheckout'
  | '_createUniversalCheckout'
>

export interface PurpositySdk extends Pick<BaseSdk, StreamlinedBaseSdk> {
  createCheckoutSession: ReturnType<typeof checkoutSession_>['create']
  expireCheckoutSession: ReturnType<typeof checkoutSession_>['expire']
  checkout: {
    universal: ReturnType<typeof universal_>
  }
  experimental: {
    device: {
      get: ReturnType<typeof getDevice_>
      init: ReturnType<typeof initDevice_>
    }
    geo: {
      getIpLocation: ReturnType<typeof getIpLocation_>
    }
    auth: {
      runSignupTasks: ReturnType<typeof runSignupTasks_>
    }
  }
  healthz: () => Promise<Response>
}
export function getPurpositySdk(config: BucketConfig): PurpositySdk {
  const bucket = createBucket(config)
  const baseSdk = bucket.gql

  const {
    _createCheckoutSession,
    _expireCheckoutSession,
    _cancelUniversalCheckout,
    _createUniversalCheckout,
    ...baseGql
  } = baseSdk

  const { create: createCheckoutSession, expire: expireCheckoutSession } =
    checkoutSession_(bucket)

  return {
    ...baseGql,
    createCheckoutSession,
    expireCheckoutSession,
    checkout: {
      universal: universal_(bucket),
    },
    experimental: {
      device: {
        get: getDevice_(bucket),
        init: initDevice_(bucket),
      },
      geo: {
        getIpLocation: getIpLocation_(bucket),
      },
      auth: {
        runSignupTasks: runSignupTasks_(bucket),
      },
    },
    async healthz() {
      return fetch(new URL('/healthz', config.gql.origin), {
        headers: baseGql.__headers,
        method: 'POST',
        credentials: 'include',
      })
    },
  }
}
