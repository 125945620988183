import { jsonSchema } from 'utils-schemas'
import * as z from 'zod'

const TraceContext = z.object({
  span_id: z.string(),
  trace_id: z.string(),
})
export const EventOperation = z.enum(['INSERT', 'UPDATE', 'DELETE', 'MANUAL'])

const genericRecordSchema = z.record(z.string(), z.any())
const narrowSchema = z.lazy(() =>
  z.discriminatedUnion('op', [
    z.object({
      op: EventOperation.extract(['INSERT']),
      data: z.object({
        old: z.null(),
        new: genericRecordSchema,
      }),
      trace_context: TraceContext,
      session_variables: z.record(z.string(), z.string()),
    }),
    z.object({
      op: EventOperation.extract(['UPDATE']),
      data: z.object({
        old: genericRecordSchema,
        new: genericRecordSchema,
      }),
      trace_context: TraceContext,
      session_variables: z.record(z.string(), z.string()),
    }),
    z.object({
      op: EventOperation.extract(['DELETE']),
      data: z.object({
        old: genericRecordSchema,
        new: z.null(),
      }),
      trace_context: TraceContext,
      session_variables: z.record(z.string(), z.string()),
    }),
    z.object({
      op: EventOperation.extract(['MANUAL']),
      data: z.object({
        old: z.null(),
        new: genericRecordSchema,
      }),
      trace_context: TraceContext,
      session_variables: z.record(z.string(), z.string()),
    }),
  ])
)
const GenericEventBody = z.object({
  op: EventOperation,
  data: z.object({
    new: z.record(z.string(), jsonSchema),
    old: z.record(z.string(), jsonSchema).nullable(),
  }),
  trace_context: TraceContext,
  session_variables: z.record(z.string(), z.string()),
})

export const EventPayloadBase = z.object({
  id: z.string().uuid(),
  trigger: z.object({
    name: z.string(),
  }),
  table: z.object({
    name: z.string(),
    schema: z.string(),
  }),
  created_at: z.string(),
  delivery_info: z.object({
    max_retries: z.number(),
    current_retry: z.number(),
  }),
  event: GenericEventBody,
})

function makeInsertPayload<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSessionVariables) {
  return EventPayloadBase.extend({
    event: createInsertEventSchema<TData, TSessionVariables>(
      schema,
      sessionVariables
    ),
  })
}

function createInsertEventSchema<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSessionVariables) {
  return z.object({
    op: z.literal(EventOperation.enum.INSERT),
    data: z.object({
      new: schema,
      old: z.null(),
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

function makeUpdatePayload<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSessionVariables) {
  return EventPayloadBase.extend({
    event: createUpdateEventSchema<TData, TSessionVariables>(
      schema,
      sessionVariables
    ),
  })
}

function createUpdateEventSchema<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSessionVariables) {
  return z.object({
    op: z.literal(EventOperation.enum.UPDATE),
    data: z.object({
      new: schema,
      old: schema,
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

function makeDeletePayload<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSession) {
  return EventPayloadBase.extend({
    event: createDeleteEventSchema<TData, TSession>(schema, sessionVariables),
  })
}

function createDeleteEventSchema<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSession) {
  return z.object({
    op: z.literal(EventOperation.enum.DELETE),
    data: z.object({
      new: z.null(),
      old: schema,
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

function makeManualPayload<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSession) {
  return EventPayloadBase.extend({
    event: createManualEventSchema<TData, TSession>(schema, sessionVariables),
  })
}
function createManualEventSchema<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSession) {
  return z.object({
    op: z.literal(EventOperation.enum.MANUAL),
    data: z.object({
      new: schema,
      old: z.null(),
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

function makeAllPayloads<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny,
>(schema: TData, sessionVariables: TSession) {
  const InsertEvent = createInsertEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  const UpdateEvent = createUpdateEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  const DeleteEvent = createDeleteEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  const ManualEvent = createManualEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  return EventPayloadBase.extend({
    event: z.discriminatedUnion('op', [
      InsertEvent,
      UpdateEvent,
      DeleteEvent,
      ManualEvent,
    ]),
  })
}
