export const PACKAGE_NAME = '@purposity/purposity-env'

type VercelEnv = 'production' | 'preview' | 'development'
type NodeEnv = 'production' | 'development' | 'test'
export type PurposityEnv = (VercelEnv | NodeEnv | 'local' | 'canary') & {}

/**
 * getPurposityEnv
 * @description use this function to declare a singleton `PURPOSITY_ENV` variable in your app.
 * The function params should be configured using literal `process.env.*` variables.
 * @example export const PURPOSITY_ENV = getPurposityEnv(process.env.VERCEL_ENV, process.env.NEXT_PUBLIC_VERCEL_ENV, process.env.NODE_ENV)
 */

export function getPurposityEnv({
  VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_ENV,
  NODE_ENV,
  DEBUG,
  CANARY,
}: {
  /**
   * @see VercelEnv
   * @use `process.env.VERCEL_ENV`
   */
  VERCEL_ENV: string | undefined
  /** @use `process.env.NEXT_PUBLIC_VERCEL_ENV` */
  NEXT_PUBLIC_VERCEL_ENV: string | undefined
  /** @use `process.env.NODE_ENV` */
  NODE_ENV: string | undefined
  /** @use `process.env.DEBUG` */
  DEBUG: string | undefined
  /** @use `process.env.PURPOSITY_CANARY_DEPLOYMENT || process.env.NEXT_PUBLIC_PURPOSITY_CANARY_DEPLOYMNENT` */
  CANARY?: string | undefined
}): PurposityEnv {
  const result = _getPurposityEnv()

  if (DEBUG)
    console.debug('getPurposityEnv', {
      params: { VERCEL_ENV, NEXT_PUBLIC_VERCEL_ENV, NODE_ENV },
      result,
    })

  return result

  function _validated() {
    return {
      VERCEL_ENV:
        VERCEL_ENV === 'production' ||
        VERCEL_ENV === 'preview' ||
        VERCEL_ENV === 'development'
          ? (VERCEL_ENV as VercelEnv)
          : undefined,
      NEXT_PUBLIC_VERCEL_ENV:
        NEXT_PUBLIC_VERCEL_ENV === 'production' ||
        NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
        NEXT_PUBLIC_VERCEL_ENV === 'development'
          ? (NEXT_PUBLIC_VERCEL_ENV as VercelEnv)
          : undefined,
      NODE_ENV:
        NODE_ENV === 'production' ||
        NODE_ENV === 'development' ||
        NODE_ENV === 'test'
          ? (NODE_ENV as NodeEnv)
          : undefined,
      DEBUG: !!DEBUG,
    }
  }

  function _getPurposityEnv(): PurposityEnv {
    const v = _validated()

    /** if running serverside on Vercel infra use `VERCEL_ENV` */
    if (v.VERCEL_ENV === 'preview') {
      return CANARY === '1' ? 'canary' : 'preview'
    }

    if (v.VERCEL_ENV === 'production') return v.VERCEL_ENV

    /**
     * if built using Vercel infra and running client-side use `NEXT_PUBLIC_VERCEL_ENV`
     * this will be inlined by most of our build tools – beware of caching
     */
    if (v.NEXT_PUBLIC_VERCEL_ENV) return v.NEXT_PUBLIC_VERCEL_ENV

    /** if running a production build locally use `local` */
    if (v.NODE_ENV === 'production') return 'local'

    /** if running a development or test build locally use `NODE_ENV` */
    if (v.NODE_ENV) return v.NODE_ENV

    /** if all else fails use `development` */
    return 'development'
  }
}
