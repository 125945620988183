import * as z from 'zod'
import { ClerkId } from '..'
import env from '../env/server'
import { createClerkJwt } from './createClerkJwt'

export const createClerkLllJwtToken = z
  .function()
  .args(ClerkId)
  .implement(async (clerkUserId) =>
    createClerkJwt(
      { userId: clerkUserId, tokenName: 'legacy-long-lived-token' },
      { apiKey: env.CLERK_SECRET_KEY }
    )
  )
