import * as z from 'zod'
import { IdSchema, invariant } from '@purposity/utils'
import { type _CancelUniversalCheckoutMutationVariables } from '../../__generated/primary/types'
import { withBucket } from '../../lib/createBucket'
import { HeadersInit, SignalInit } from '../_common'
import type { Properties } from '../../utils'

type Params = _CancelUniversalCheckoutMutationVariables
const Params = z.object<Properties<Params>>({
  params: z.object({
    checkout_intent_id: IdSchema,
  }),
})

export default withBucket((bucket) => {
  return z
    .function()
    .args(Params, HeadersInit, SignalInit)
    .implement(async (params, headersInit, signalInit) => {
      const result = await bucket.gql._cancelUniversalCheckout(
        params,
        headersInit
      )
      invariant(result.checkout, 'No checkout returned')
      return result.checkout
    })
})
