import runtime from '../../runtime'
import { mulberry32 } from './mulberry32'

const genRandom = () => {
  const generate = mulberry32(Date.now())
  // generate random 32 character string
  return (
    generate().toString(36).substring(2, 15) +
    generate().toString(36).substring(2, 15)
  )
}

export const getRandomId = () => {
  try {
    return runtime.crypto.randomUUID()
  } catch {
    return genRandom()
  }
}
