import * as z from 'zod'

/**
 * degreees north or south of the Equator
 * @example 38.895
 */

export const Latitude = z.coerce
  .number()
  .refine((arg) => arg > -90 && arg < 90, 'Latitude must be between -90 and 90')
/**
 * degrees east or west of the Prime Meridian
 * @example -77.036
 */

export const Longitude = z.coerce
  .number()
  .refine(
    (arg) => arg > -180 && arg < 180,
    'Longitude must be between -180 and 180'
  )

export const GeoBase = z.object({
  latitude: Latitude,
  longitude: Longitude,
  city: z.string(),
  country: z.string(),
  region: z.string(),
})
export const Geo = GeoBase.transform((value) => {
  return {
    ...value,
    point: {
      type: 'Point',
      coordinates: [value.longitude, value.latitude],
    },
  }
})

export type GeoInput = z.input<typeof Geo>

export type Geo = z.output<typeof Geo>
