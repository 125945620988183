import * as z from 'zod'

export const HasuraErrorResponse = z.object({
  message: z.string(),
  extensions: z
    .object({
      code: z.string(),
      internal: z.literal(true).optional(),
    })
    .catchall(z.union([z.string(), z.number(), z.boolean()]))
    .optional(),
})

export function makeHasuraActionResponse<TSchema extends z.ZodTypeAny>(
  schema: TSchema
) {
  return z.union([schema, HasuraErrorResponse])
}

export function makeHasuraEventResponse<TSchema extends z.ZodTypeAny>(
  schema: TSchema
) {
  return z.union([schema, HasuraErrorResponse])
}
