import { IdSchema } from 'utils-schemas'
import * as z from 'zod'
import type { JwtPayload } from '@clerk/types'
import {
  combinedRoles,
  orgId,
  sessionId,
  StripeCustomerId,
} from '~/lib/clerk/_shared'
import { Permissions, Roles } from '~/lib/clerk/authorization'
import { OrgEntityType } from '~/lib/clerk/metadata.organization'

export const TEMPLATE_NAME = 'admin-panel' as const

/**
  * `admin-panel` template
  * ```json
    {
      "orgs": "{{user.organizations}}",
      "org_id": "{{org.id}}",
      "org_role": "{{org.role}}",
      "org_slug": "{{org.slug}}",
      "org_type": "{{org.public_metadata.entity.type}}",
      "purposity": {
        "oid": "{{org.public_metadata.entity.id || '0'}}",
        "sid": "{{user.public_metadata.stripe_customer_id}}",
        "uid": "{{user.public_metadata.purposity_id}}",
        "role": "{{ org.role || 'user_public' }}",
        "roles": [
          "user_public",
          "{{ org.role || 'user_public' }}"
        ],
      },
      "org_permissions": "{{org_membership.permissions}}"
    }
    ```
  * @example
  * {
      "azp": "http://localhost:3020",
      "exp": 1721678223,
      "iat": 1721618223,
      "iss": "https://clerk.feasible.herring-93.lcl.dev",
      "jti": "38fba45415ded2199c14",
      "nbf": 1721618218,
      "org_id": "org_2jaCIxW7NjlwefOU33oTTXfGJyX",
      "org_permissions": [
        "org:manage:needs",
        "org:manage:organizations",
        "org:manage:sponsors",
        "org:manage:stories",
        "org:manage:teams",
        "org:manage:thank_yous",
        "org:manage:users"
      ],
      "org_role": "org:purposity_admin",
      "org_slug": "purposity-team",
      "org_type": "administrative",
      "orgs": {
        "org_2QqEzzTrZXtqQvE8faiBLPAtXom": "org:purposity_admin",
        "org_2XUXfYLzrX8fyUieNF0SCv6ZyBe": "org:purposity_admin",
        "org_2XVtJfYz7jjvDfsy63HNM0Feavd": "org:purposity_admin",
        "org_2XaOzBVttUxAm3tmchlYbeDP2kz": "org:organizer",
        "org_2jaCIxW7NjlwefOU33oTTXfGJyX": "org:purposity_admin"
      },
      "purposity": {
        "oid": "0",
        "role": "org:purposity_admin",
        "roles": [
          "user_public",
          "org:purposity_admin"
        ],
        "session_actor": null,
        "sid": "cus_O1qioFjhFXVHKh",
        "uid": "117"
      },
      "sid": "{{session.id}}",
      "sub": "user_2Qo8DRIoRM1Wx6BxBX87IpZsuXM"
    }
 */

export const extendedClaims = z.object({
  orgs: z.record(orgId, Roles),
  org_id: orgId,
  org_role: Roles,
  org_slug: z.string().min(1),
  org_type: OrgEntityType,
  purposity: z.object({
    oid: IdSchema,
    role: combinedRoles,
    roles: combinedRoles.array(),
    session_actor: z.string().nullable(),
    sid: StripeCustomerId,
    uid: IdSchema,
  }),
  org_permissions: Permissions.array(),
})

export type ExtendedClaims = Prettify<z.infer<typeof extendedClaims>>
