import { IdSchema } from 'utils-schemas'
import * as z from 'zod'
import { combinedRoles, orgId, StripeCustomerId } from './_shared'
import { ClerkDefaultSessionClaims } from './jwt-claims.clerk-default'
import { Roles } from '~/lib/clerk/authorization'
import { OrgEntityType } from '~/lib/clerk/metadata.organization'

export const PurposityClaims = z.object({
  /** Purposity User ID
   * @field "users.id"
   * @example 117
   */
  uid: IdSchema,

  /** User’s Purposity Organization ID
   * @field "users.organization_id"
   * @example 23
   */
  oid: IdSchema.nullish(),

  /** Roles
   * @field derived from "users.roles_mask"
   * @example ['user_public', 'user']
   */
  roles: z.array(combinedRoles).default(['user_public']),

  /** Role
   * @field extracted from Clerk `org_role`
   * @example org:organizer
   */
  role: combinedRoles,

  /** Stripe Customer ID
   * @field "users.stripe_token"
   * @example 'cus_HgeLKXFfLDMoBy'
   */
  sid: StripeCustomerId,
  orgs: z.record(orgId, Roles),
})

export type PurposityClaims = Prettify<z.infer<typeof PurposityClaims>>

export const ExtendedJwtSessionClaims = z.object({
  org_type: OrgEntityType.optional(),
  purposity: PurposityClaims,
})
export type ExtendedJwtSessionClaims = Prettify<
  z.infer<typeof ExtendedJwtSessionClaims>
>
export const HasuraNextJwtClaimsSchema = ExtendedJwtSessionClaims.and(
  ClerkDefaultSessionClaims
)

export type HasuraNextJwtClaimsSchema = Prettify<
  z.infer<typeof HasuraNextJwtClaimsSchema>
>
