import * as z from 'zod'

const PHONE_REGEX =
  /^(?<countryCode>\+?1)?\(?(?<areaCode>[0-9]{3})\)?[-. ]?(?<exchange>[0-9]{3})[-. ]?(?<subscriber>[0-9]{4})$/

export const PhoneNumber = z.coerce
  .string()
  .regex(PHONE_REGEX, {
    message: 'Phone number must match PHONE_REGEX',
  })
  .transform((val) => {
    const result = PHONE_REGEX.exec(val)

    const { areaCode, exchange, subscriber } = result?.groups as {
      countryCode?: string
      areaCode: string
      exchange: string
      subscriber: string
    }

    const number = `+1${areaCode}${exchange}${subscriber}`
    return number
  })
