import React, {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import AsyncStorage from '@react-native-async-storage/async-storage'

const KEY = '@purposity/flags'

export enum Flag {
  testAuth = 'testAuth',
  blueDev = 'blueDev',
  allowPhone = 'allowPhone',
  showHiddenFormFields = 'showHiddenFormFields',
  enableWebsocket = 'enableWebsocket',
  showReactQueryDevTools = 'showReactQueryDevTools',
  enableNewAdminFeatures = 'enableNewAdminFeatures',
  enableHasuraCookieAuth = 'enableHasuraCookieAuth',
}

type State = Record<Flag, boolean>

const DEFAULT_STATE: State = {
  testAuth: false,
  blueDev: true,
  allowPhone: true,
  showHiddenFormFields: false,
  enableWebsocket: false,
  showReactQueryDevTools: true,
  enableNewAdminFeatures: false,
  enableHasuraCookieAuth: true,
}

type Action = { target: Flag; type: 'true' | 'false' | 'toggle' }

type Dispatch = (action: Action) => void
type FlagProviderProps = { children: ReactNode }

const FlagStateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function flagReducer(state: State, action: Action) {
  switch (action.type) {
    case 'true': {
      return {
        ...state,
        [action.target]: true,
      }
    }
    case 'false': {
      return {
        ...state,
        [action.target]: true,
      }
    }
    case 'toggle': {
      return {
        ...state,
        [action.target]: !state[action.target],
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const getInitialState = async (): Promise<State> => {
  if (typeof window === 'undefined') {
    return DEFAULT_STATE
  }
  const savedItem: string | undefined | null = await AsyncStorage.getItem(KEY)

  let parsed = DEFAULT_STATE

  try {
    if (savedItem) parsed = JSON.parse(savedItem)
  } catch (error) {
    console.log(error)
  }
  return parsed
}

function FlagProvider({ children }: FlagProviderProps) {
  const initialStateQuery = useQuery({
    queryKey: ['flags'],
    queryFn: getInitialState,
    initialData: DEFAULT_STATE,
  })

  const [state, dispatch] = useReducer(flagReducer, initialStateQuery.data)
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  useEffect(() => {
    function isEqual(a: State, b: State) {
      let key: keyof State

      for (key in a) {
        if (a[key] !== b[key]) {
          return false
        }
      }
      return true
    }

    const checkEquality = async () => {
      if (!isEqual(value.state, DEFAULT_STATE)) {
        await AsyncStorage.setItem(KEY, JSON.stringify(value.state))
      } else {
        await AsyncStorage.removeItem(KEY)
      }
    }
    checkEquality()
  }, [value])

  return (
    <FlagStateContext.Provider value={value}>
      {children}
    </FlagStateContext.Provider>
  )
}

function useFlags() {
  const context = useContext(FlagStateContext)
  if (context === undefined) {
    throw new Error('useFlags must be used within a FlagsProvider')
  }
  return context
}

export { FlagProvider, useFlags }
