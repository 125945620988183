export function isNeedMet(need: any) {
  if (need?.is_met_by_current_user) return true
  if (need?.completed_at) return true
  if (need?.state === 'purchase_unverified') return true
  if (need?.state === 'purchase_verified') return true
  if (need?.state === 'received') return true
  if (need?.need_fulfill_intents?.length > 0) return true
  if (need?.checkout_intent?.id) return true

  if (need?.met_by_id) return true

  return false
}
