import { graphql } from '../../graphql'
import { makeApiMethod } from '../../make-api-method'

const document = graphql(`
  query getIpLocation {
    geo: get_ip_location {
      latitude
      longitude
      city
      country
      region
      point
    }
  }
`)

export default makeApiMethod(document)
