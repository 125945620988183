import type { ResponseMiddleware } from 'graphql-request'
import { captureMessage, withScope } from '~/universal/sentry'

export const handleSdkErrors: ResponseMiddleware = (response) => {
  if ('errors' in response) {
    const traceId = response.headers.get('x-b3-traceid') || 'unknown'
    console.error(
      `[${traceId}] Request error:
        status ${response.status}
        details: ${response.errors}`
    )

    withScope((scope) => {
      response.errors?.forEach((error) => {
        captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            graphqlRequestGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        })
        if (error.path) {
          // We can also add the path as breadcrumb
          scope.addBreadcrumb({
            category: 'query-path',
            message: error.path.join(' > '),
            level: 'debug',
          })
        }
      })
    })
  }
}
