import { consoleTransport } from './transports/console'
// import { consoleTransport } from 'react-native-logs'
import type { Level } from './shared'
import type { configLoggerType as ConfigLoggerType } from 'react-native-logs'

export const defaultConfig: ConfigLoggerType = {
  severity: 'debug',
  transport: consoleTransport,
  transportOptions: {
    levels: {
      trace: 0,
      debug: 1,
      info: 2,
      inspect: 2,
      warn: 3,
      error: 4,
    } as Record<Level, number>,
  },

  printLevel: false,
  printDate: true,

  dateFormat(date) {
    return date.getMinutes() + ':' + date.getSeconds()
  },
  formatFunc(level, extension, msgs) {
    return [extension ? extension : '', ...msgs].join(' ')
  },
}
