import type { IncomingMessage, IncomingHttpHeaders } from 'http'

/**
 * getBaseUrl
 * @see https://github.com/tajultonim/nextjs-url for original source
 **/
export function getBaseUrl(req?: Request | IncomingMessage) {
  if (typeof window !== 'undefined') {
    return new URL(window.location.origin)
  }

  if (typeof req === 'undefined') {
    throw new Error('Could not determine base URL')
  }

  const headers = convertToHeaders(req.headers)

  const referer = headers.has('referer')
    ? new URL(headers.get('referer')!)
    : undefined
  const baseurlhost = headers.get('host')

  if (
    referer &&
    referer.host === baseurlhost &&
    headers.get('sec-fetch-site') === 'same-origin'
  ) {
    return new URL(referer.origin)
  } else if (baseurlhost) {
    const protocol =
      baseurlhost.split(':')[0] === 'localhost'
        ? 'http'
        : baseurlhost.split(':')[0] === '127.0.0.0'
        ? 'http'
        : 'https'
    return new URL(protocol + '://' + baseurlhost)
  }

  throw new Error('Could not determine base URL')
}

export function getApiRoot(req?: Request | IncomingMessage) {
  return new URL(getBaseUrl(req).origin + '/api')
}

export function getBaseUrlSafe(req?: Request | IncomingMessage) {
  try {
    return getBaseUrl(req)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

function convertToHeaders(
  headers: Headers | HeadersInit | IncomingHttpHeaders
): Headers {
  if (headers instanceof Headers) {
    return headers
  } else if (Array.isArray(headers)) {
    const convertedHeaders = new Headers()
    for (const [key, value] of headers) {
      convertedHeaders.append(key, value)
    }
    return convertedHeaders
  } else {
    return _incomingHttpHeaderstoHeaders(headers)
  }

  function _incomingHttpHeaderstoHeaders(
    headers: IncomingHttpHeaders
  ): Headers {
    const convertedHeaders = new Headers()
    for (const [key, value] of Object.entries(headers)) {
      if (Array.isArray(value)) {
        for (const v of value) {
          convertedHeaders.append(key, v)
        }
      } else if (typeof value === 'string') {
        convertedHeaders.append(key, value)
      } else {
        // do nothing
      }
    }
    return convertedHeaders
  }
}
