import { useEffect } from 'react'
import type { QueryKey } from '@tanstack/react-query'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { appConfig } from 'config'

export type MutationContext<TSnapshot = any> = {
  snapshot?: TSnapshot
}

export interface ExtendedQueryMeta {
  headers?: HeadersInit
  pageQuantity?: number
}

export interface ExtendedMutationMeta {
  headers?: HeadersInit
}

export type SnapshotDetail<
  TQueryKey extends QueryKey,
  TSnapshotData extends any,
> = {
  key: TQueryKey
  data?: TSnapshotData
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
      retry: __DEV__ || appConfig.purposityEnv === 'development' ? false : 3,
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
  mutationCache: new MutationCache({
    onError(_error, variables, context, mutation) {
      //
    },
  }),
  queryCache: new QueryCache({
    onSettled(data, error, query) {
      if (query.queryKey.includes('owner')) {
        //
      }
    },
    onError(_error, query) {
      if (query.state.data !== undefined) {
        // data is already in the cache, error when trying to revalidate
      }
    },
  }),
})

export function useQueryClientPersist(queryClient: QueryClient) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (appConfig.purposityEnv !== 'development') {
        const persister = createAsyncStoragePersister({
          storage: AsyncStorage,
          key: '@purposity/query-cache',
        })

        const keyVersion = 2

        const cacheKey: readonly [
          keyVersion: number,
          busterType: 'build' | 'date',
          busterValue: string,
        ] = __NEXT_DATA__
          ? [keyVersion, 'build', __NEXT_DATA__.buildId]
          : [keyVersion, 'date', new Date().toLocaleDateString()]

        persistQueryClient({
          buster: JSON.stringify(cacheKey),
          queryClient,
          persister,
        })
      }
    }
  }, [queryClient])
}
