/**
 * @see https://github.com/clerkinc/javascript/blob/3257de5b73ad87f7921ce5cf4fce80f9c54cca26/packages/backend/src/runtime/index.ts
 */
// @ts-ignore
import crypto from '#crypto'

type Runtime = {
  crypto: Crypto
}

const runtime: Runtime = {
  crypto,
}

export default runtime
