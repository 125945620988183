import * as z from 'zod'

/** Custom Clerk Permissions
 * @see [Dev: Organization Settings](https://dashboard.clerk.com/apps/app_26wOxoykXjma7XbWgeG6OVXAUm0/instances/ins_26wOxrC1nbcqacePaRJ0vjmAzvH/organizations-settings/permissions)
 * @see [Prod: Organization Settings](https://dashboard.clerk.com/apps/app_26wOxoykXjma7XbWgeG6OVXAUm0/instances/ins_277VUJeLoecLmGToM6s8o5CjMgv/organizations-settings/permissions)
 */
const _permissions = {
  VIEW_SOURCE_MAPS: 'org:source_maps:view',
  MANAGE_NEEDS: 'org:manage:needs',
  MANAGE_ORGANIZATIONS: 'org:manage:organizations',
  MANAGE_SPONSORS: 'org:manage:sponsors',
  MANAGE_STORIES: 'org:manage:stories',
  MANAGE_TEAMS: 'org:manage:teams',
  MANAGE_THANK_YOUS: 'org:manage:thank_yous',
  MANAGE_USERS: 'org:manage:users',
  NEEDS_SUBMIT_TO_PURPOSITY: 'org:needs:submit_to_purposity',
} as const

export const Permissions = z.nativeEnum(_permissions)

/** `org:<resource>:<action>` */
export type Permissions = z.infer<typeof Permissions>

/** Custom Clerk Roles
 * @see [Dev: Organization Settings](https://dashboard.clerk.com/apps/app_26wOxoykXjma7XbWgeG6OVXAUm0/instances/ins_26wOxrC1nbcqacePaRJ0vjmAzvH/organizations-settings/roles)
 * @see [Prod: Organization Settings](https://dashboard.clerk.com/apps/app_26wOxoykXjma7XbWgeG6OVXAUm0/instances/ins_277VUJeLoecLmGToM6s8o5CjMgv/organizations-settings/roles)
 */
const _roles = {
  PURPOSITY_ADMIN: 'org:purposity_admin',
  ORG_CREATOR: 'org:org_creator',
  ORG_ADMIN: 'org:org_admin',
  ORGANIZER: 'org:organizer',
} as const

export const Roles = z.nativeEnum(_roles)
export type Roles = z.infer<typeof Roles>
