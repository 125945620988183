import * as z from 'zod'

/**
 * # Environment Utilities
 */

type NextPublicKey = `NEXT_PUBLIC_${Uppercase<string>}`
type IsValidNextPublicKey<T> = T extends `${infer U extends NextPublicKey}`
  ? U
  : never

export type OnlyNextPublicEnv<
  TProcessEnv extends Record<string, string | undefined>
> = {
  [key in keyof TProcessEnv as IsValidNextPublicKey<key>]: TProcessEnv[key]
}

type IsNotNextPublicKey<T> = T extends `${infer U extends NextPublicKey}`
  ? never
  : T
export type WithoutNextPublicEnv<
  TProcessEnv extends Record<string, string | undefined>
> = {
  [key in keyof TProcessEnv as IsNotNextPublicKey<key>]: TProcessEnv[key]
}

/**
 * # Zod Utilities
 */

export function ensureKeys<T>() {
  return <X extends Record<keyof T, unknown>>(
    value: X & Record<never, never>
  ) => value
}

export const schemaForType =
  <T>() =>
  <S extends z.ZodType<T, any, any>>(arg: S) => {
    return arg
  }

export type ShapeFromData<Data extends Record<string, any> | undefined> =
  Data extends undefined
    ? never
    : {
        [key in keyof Data]-?: unknown extends Data[key]
          ? z.ZodType<Data[key]> | z.ZodOptional<z.ZodType<Data[key]>>
          : undefined extends Data[key]
          ? z.ZodOptional<z.ZodType<Data[key]>>
          : z.ZodType<Data[key]>
      }

export type ZodProperties<Input> = Required<{
  [K in keyof Input]: z.ZodType<Input[K], any, Input[K]>
}>

export type ZodTxProperties<Input> = Required<{
  [K in keyof Input]: z.ZodType<Input[K], z.ZodTypeDef, Input[K]>
}>

/**
 * #Schemas
 */

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
type Literal = z.infer<typeof literalSchema>
export type Json = Literal | { [key: string]: Json } | Json[]
export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
)

export const dateSchema = z.preprocess((arg) => {
  if (typeof arg == 'string' || arg instanceof Date || typeof arg === 'number')
    return new Date(arg)
}, z.date())
export type dateSchema = z.infer<typeof dateSchema>

/**
 * Utility
 */

export type PartialWithRequired<
  TAny,
  TKey extends keyof TAny
> = Partial<TAny> & {
  [Property in TKey]-?: TAny[Property]
}

export const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[]
}
