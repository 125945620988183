import * as z from 'zod'
import { looseHrefSchema } from './urls'

export const VercelEnvSchema = z.object({
  VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  VERCEL_GIT_COMMIT_AUTHOR_LOGIN: z.string(),
  VERCEL_GIT_COMMIT_AUTHOR_NAME: z.string(),
  VERCEL_GIT_COMMIT_MESSAGE: z.string(),
  VERCEL_GIT_COMMIT_REF: z.string(),
  VERCEL_GIT_COMMIT_SHA: z.string(),
  VERCEL_GIT_PROVIDER: z.string(),
  VERCEL_GIT_REPO_ID: z.string().or(z.number()),
  VERCEL_GIT_REPO_OWNER: z.string(),
  VERCEL_GIT_REPO_SLUG: z.string(),
  VERCEL_URL: looseHrefSchema,
})
export type VercelEnvSchema = z.infer<typeof VercelEnvSchema>

export const NextPublicVercelEnvSchema = z.object({
  NEXT_PUBLIC_VERCEL_ENV: VercelEnvSchema.shape.VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN:
    VercelEnvSchema.shape.VERCEL_GIT_COMMIT_AUTHOR_LOGIN,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME:
    VercelEnvSchema.shape.VERCEL_GIT_COMMIT_AUTHOR_NAME,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE:
    VercelEnvSchema.shape.VERCEL_GIT_COMMIT_MESSAGE,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
    VercelEnvSchema.shape.VERCEL_GIT_COMMIT_REF,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    VercelEnvSchema.shape.VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_GIT_PROVIDER: VercelEnvSchema.shape.VERCEL_GIT_PROVIDER,
  NEXT_PUBLIC_VERCEL_GIT_REPO_ID: VercelEnvSchema.shape.VERCEL_GIT_REPO_ID,
  NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER:
    VercelEnvSchema.shape.VERCEL_GIT_REPO_OWNER,
  NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG: VercelEnvSchema.shape.VERCEL_GIT_REPO_SLUG,
  NEXT_PUBLIC_VERCEL_URL: VercelEnvSchema.shape.VERCEL_URL,
})
export type NextPublicVercelEnvSchema = z.infer<
  typeof NextPublicVercelEnvSchema
>
