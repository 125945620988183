import * as z from 'zod'
import { IdSchema } from './id'

export const IdWithAlias = z.object({
  id: IdSchema,
  alias: z.string(),
  v_alias: z.string(),
})

export type IdWithAlias = z.infer<typeof IdWithAlias>
