import { useQueryWithAuth } from '@lib/Auth/clerk-utils'
import { deviceInfoQK } from '@lib/global-setup/features/query-keys'
import { GeoBase } from '@purposity/sdk/schemas'
import { sdk } from '@services/sdk'
import { useQuery } from '@tanstack/react-query'
import * as Location from 'expo-location'
import ms from 'ms'
import { Platform } from 'react-native'
import { createParam } from 'solito'
import type * as z from 'zod'

type GeoBase = z.infer<typeof GeoBase>

const MinGeo = GeoBase.partial({
  city: true,
  region: true,
  country: true,
})
type MinGeo = z.infer<typeof MinGeo>

const MaybeGeo = MinGeo.nullable()
type MaybeGeo = z.infer<typeof MaybeGeo>

const { useParams } = createParam<MinGeo>()

function useGeoParams() {
  const { params } = useParams()
  return MaybeGeo.catch(null).parse(params)
}

const getGeoApi = async () => {
  const geoRequest = await fetch('https://geoip.purposity.app')
  const geo = await geoRequest.json()
  return {
    geo
  }
}

export function useGeo() {
  const urlParamsGeo = useGeoParams()

  const { data: expoGeo } = useExpoGeo()

  const { data: ipGeo } = useQueryWithAuth({
    ...deviceInfoQK.geo('ip'),
    async queryFn(context) {
      return getGeoApi()
    },
    enabled: Platform.OS === 'web',
    select: ({ geo }) => MaybeGeo.catch(null).parse(geo),
    retry: false,
    staleTime: ms('1h'),
  })

  return urlParamsGeo || expoGeo || ipGeo
}

function useExpoGeo() {
  return useQuery({
    ...deviceInfoQK.geo('expo'),
    async queryFn() {
      const locPermission = await Location.getForegroundPermissionsAsync()
      if (!locPermission.granted) {
        return null
      }

      const position = await Location.getCurrentPositionAsync({
        accuracy: Location.LocationAccuracy.Lowest,
        distanceInterval: 1500,
      })
      const [revGeocode] = await Location.reverseGeocodeAsync(position.coords)

      return MaybeGeo.catch(null).parse({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        city: revGeocode?.city,
        region: revGeocode?.region,
        country: revGeocode?.country,
      })
    },
    enabled: Platform.OS === 'ios' || Platform.OS === 'android',
    staleTime: ms('1h'),
    cacheTime: Infinity,
    keepPreviousData: true,
  })
}

export function useGeoDebug() {
  const urlParamsGeo = useGeoParams()

  const expoGeo = useExpoGeo()

  const ipGeo = useQueryWithAuth({
    ...deviceInfoQK.geo('ip'),
    async queryFn(context) {
      return sdk.experimental.geo.getIpLocation(
        {},
        context.meta?.headers,
        context.signal
      )
    },
    enabled: Platform.OS === 'web',
    select: ({ geo }) => MaybeGeo.catch(null).parse(geo),
    retry: false,
    staleTime: ms('1h'),
  })

  return { urlParamsGeo, expoGeo, ipGeo }
}
