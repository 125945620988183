import { graphql } from '../../graphql'
import { makeApiMethod } from '../../make-api-method'

const document = graphql(`
  mutation newDevice($id: Int!) {
    insert_devices(objects: { id: $id }) {
      affected_rows
      returning {
        id
      }
    }
  }
`)

export default makeApiMethod(document)
