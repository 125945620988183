import '../custom-globals'
import 'raf/polyfill'
import 'setimmediate'
import * as React from 'react'
import {
  Hydrate as ReactQueryHydrate,
  QueryClientProvider,
} from '@tanstack/react-query'
import Head from 'next/head'
import { Analytics } from '@vercel/analytics/react'
import { appConfig } from 'config'
import { env } from 'env'
import { SolitoImageProvider } from 'solito/image'
import { trpc } from 'utils/trpc'
import { createCloudflareImageUrl } from '@purposity/utils'
import { Favicon } from '@components/Favicon'
import { GlobalSeo } from '@components/SEO/FallbackSeo'
import { AuthProvider } from '@features/auth/components/AuthProvider'
import { DevTools } from '@features/dev-tools'
import { KEY } from '@features/dev-tools/constants'
import { useEnabled } from '@features/dev-tools/hooks/useEnabled'
import { FlagProvider } from '@features/flags/context'
import { RoadblockModal } from '@features/roadblock/components/RoadblockModal'
import { RoadblockProvider } from '@features/roadblock/hooks/useRoadblock'
import Notifications from '@features/updates/components/Toasts'
import { ClerkRefresh } from '@lib/Auth/clerk-refresh'
import ReactNativeNextScrollRestore from '@lib/global-setup/react-native-next-scroll-restore'
import * as ReactQueryService from '@lib/react-query'
import { useAnalyticsPageTracking } from '@services/Analytics/useAnalyticsPageTracking'
import type { AppPropsWithLayout } from 'types/next'
import '@purposity/icons/styles.css'
import { FullScreenLayoutProvider } from '~/layouts/FullScreenLayout.context'

ReactNativeNextScrollRestore.initialize()

function App({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout<{ dehydratedState?: any }>) {
  useAnalyticsPageTracking(router)

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <AuthProvider router={router}>
      <SolitoImageProvider
        loader={({ quality, src, width }) => {
          return createCloudflareImageUrl(
            src,
            new URL(env.NEXT_PUBLIC_CDN_ORIGIN),
            {
              width,
              quality,
            }
          )
        }}
      >
        <InnerProvider>
          <ReactQueryHydrate state={pageProps.dehydratedState}>
            <FlagProvider>
              <AllConfiguredProvider>
                {getLayout(<Component {...pageProps} />)}
              </AllConfiguredProvider>
            </FlagProvider>
          </ReactQueryHydrate>
        </InnerProvider>
      </SolitoImageProvider>
    </AuthProvider>
  )
}

function InnerProvider(props: { children: React.ReactNode }) {
  const [queryClient] = React.useState(() => ReactQueryService.queryClient)

  ReactQueryService.useQueryClientPersist(queryClient)

  return (
    <QueryClientProvider client={queryClient}>
      <FullScreenLayoutProvider>
        <RoadblockProvider>
          <>{props.children}</>

          <Analytics />
          <Favicon />

          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <GlobalSeo />
            <link
              key="cdn-preconnect"
              rel="preconnect"
              href={appConfig.cdnURL.href}
            />
            <link
              key="cdn-prefetch"
              rel="dns-prefetch"
              href={appConfig.cdnURL.href}
            />
            <link
              key="api-preconnect"
              rel="preconnect"
              href={appConfig.hasuraOrigin.href}
            />
            <link
              key="api-prefetch"
              rel="dns-prefetch"
              href={appConfig.hasuraOrigin.href}
            />
            <link
              key="auth-preconnect"
              rel="preconnect"
              href={appConfig.clerkOrigin.href}
            />
            <link
              key="auth-prefetch"
              rel="dns-prefetch"
              href={appConfig.clerkOrigin.href}
            />
          </Head>

          <ClerkRefresh />
          <Notifications />
          <RoadblockModal />
        </RoadblockProvider>
      </FullScreenLayoutProvider>
    </QueryClientProvider>
  )
}

export default trpc.withTRPC(App)

function AllConfiguredProvider(props: React.PropsWithChildren) {
  const enabled = useEnabled(KEY)

  return (
    <>
      <>{props.children}</>
      {enabled && <DevTools />}
    </>
  )
}
