import { slate, slateA, sky } from '@radix-ui/colors'
import { createStitches } from '@stitches/react'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      ...slate,
      ...slateA,
      ...sky,
      white: '#ffffff',
      backgroundGray: '#fafafd',
      wildSand: '#f6f6f6',
      athensGray: '#f4f5f8',
      altWhite: '#f4f5f8',
      neutralGray: '#ebebeb',
      dotGray: '#cdced4',
      purchasedGray: '#c0c1c7',
      textGray: '#91939c',
      descriptionGray: '#646670',
      abbyGray: '#4a4c54',
      outerSpace: '#373a3c',
      originalBlack: '#34353b',
      coal: '#34353b',
      alternateGray: '#131417',
      realBlack: '#000000',
      black: '#000000',
      red: '#ff0000',
      tomato: '#ff6347',
      yellow: '#fbc817',
      snapYellow: '#fffc00',
      green: '#53ce84',
      darkBlue: '#1c2a38',
      seaBlue: '#1a6b86',
      blue: '#22c4f6',
      primary: '#33cfff',
      actionBlue: 'rgba(20,124,250,1)',
      fbBlue: '#3b5998',
      purple: '#9d60c9',
      alertPink: '#fa246f',
      gray400: 'gainsboro',
      gray500: 'lightgray',
    },
    fonts: {
      base: 'Avenir-Book',
      bold: 'SofiaPro-Bold, sofia-pro',
      emphasis: 'HelveticaNeue-Italic',
      alternateBold: 'SourceSansPro-SemiBold',
      sourceBold: 'SourceSansPro-Bold',
      regular:
        'SourceSansPro-Regular, "Source Sans Pro", system-ui, sans-serif',
      sofia: 'SofiaProRegular',
      gilroy: 'Gilroy-Regular',
      gilroyBold: 'Gilroy-Bold',
    },
    fontWeights: {
      base: 'normal',
      bold: 'bold',
      emphasis: 'normal',
      alternateBold: 'bold',
      sourceBold: 'bold',
      regular: 'normal',
      sofia: 'bold',
      gilroy: 'normal',
      gilroyBold: 'bold',
    },
    fontSizes: {
      h1: '38px',
      h2: '34px',
      h3: '30px',
      h4: '26px',
      h5: '22px',
      h6: '20px',
      input: '18px',
      regular: '16px',
      regium: '15px',
      medium: '13px',
      small: '12px',
      tiny: '9px',
    },
  },
  media: {
    bp1: '(min-width: 480px)',
  },
  utils: {
    marginX: (value: string | number) => ({
      marginLeft: value,
      marginRight: value,
    }),
  },
})
